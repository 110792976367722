import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isArray, isFunction } from 'lodash-es';
import { BehaviorSubject, isObservable, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AutocompleteDataSource } from './autocomplete-data-source.model';
import { AutocompleteOptions } from './autocomplete-options.model';
import { AutocompleteResult } from './autocomplete-result.model';

@Component({
  selector: 'cim-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T extends AutocompleteResult = any> implements OnInit, OnDestroy {
  private subscriptions: { [key: string]: Subscription } = {};
  private getData?: (query: string) => Observable<Array<T>>;
  private hits$ = new BehaviorSubject<Array<T>>([]);
  private _options = new AutocompleteOptions();

  public form = new UntypedFormGroup({
    query: new UntypedFormControl('')
  });

  public displayedHits: Array<T> = [];
  public isOpen = false;

  public templateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  public iconTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  public footerTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  public isActive = false;

  @ContentChild('hitTemplate', { static: true })
  set templateRef(value: TemplateRef<any>) {
    this.templateRef$.next(value);
  }

  @ContentChild('iconTemplate', { static: true })
  set iconTemplateRef(value: TemplateRef<any>) {
    this.iconTemplateRef$.next(value);
  }

  @ContentChild('footerTemplate', { static: true })
  set footerTemplateRef(value: TemplateRef<any>) {
    this.footerTemplateRef$.next(value);
  }

  @Input()
  set datasource(value: AutocompleteDataSource<T>) {
    if (value) {
      if (isFunction(value.data)) {
        this.getData = value.data;
      } else if (isObservable(value.data)) {
        this.subscriptions.data = (value.data.subscribe(hits => this.hits$.next(hits)));
      } else if (isArray(value.data)) {
        this.hits$.next(value.data);
      }
    }
  }

  @Input()
  set options(value: AutocompleteOptions) {
    this._options = value || new AutocompleteOptions();
  }

  get options(): AutocompleteOptions {
    return this._options;
  }

  @Output()
  queryChanged = new EventEmitter<string>();

  @Output()
  hitClicked = new EventEmitter<T>();

  @Output()
  enterPressed = new EventEmitter<string>();

  ngOnInit(): void {
    this.subscriptions.hits = this.hits$.subscribe(hits => {
      this.displayedHits = hits.slice(0, this.options?.displayedHitsSize || hits.length);
    });

    this.form.valueChanges
      .pipe(debounceTime(this.options.debounceTime))
      .subscribe(value => {
        this.queryChanged.next(value?.query);
        this.isOpen = true;

        if (this.getData) {
          if (this.subscriptions.getData) {
            this.subscriptions.getData.unsubscribe();
          }
          this.subscriptions.getData = this.getData(value?.query).subscribe(hits => {
            this.hits$.next(hits);
          });
        }
      });
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  blur(): void {
    this.isOpen = false;
  }

  onHitClicked(hit: T): void {
    this.hitClicked.next(hit);
  }

  onEnterPressed(form: UntypedFormGroup): void {
    this.enterPressed.next(form.get('query')?.value);
  }

  activateForm(): void {
    this.isActive = true;
  }

  deactivateForm(): void {
    this.isActive = false;
  }
}
