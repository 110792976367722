import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { DataAttributesModule } from '../../../widgets/data-attributes/data-attributes.module';
import { RatingModule } from '../../../widgets/rating/rating.module';
import { RedirectModule } from '../../../widgets/redirect/redirect.module';
import { SoftwareSectionTileComponent } from './software-section-tile.component';

@NgModule({
  declarations: [
    SoftwareSectionTileComponent
  ],
  imports: [
    CommonModule,
    RatingModule,
    RedirectModule,
    IconModule,
    DataAttributesModule
  ],
  exports: [
    SoftwareSectionTileComponent
  ]
})
export class SoftwareSectionTileModule { }
