import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarStateService {
  private hasBoxShadow$ = new BehaviorSubject<boolean>(true);
  private isFolded$ = new BehaviorSubject<boolean>(false);

  public updateBoxShadow(update: boolean): void {
    this.hasBoxShadow$.next(update);
  }

  public getBoxShadow(): Observable<boolean> {
    return this.hasBoxShadow$.asObservable();
  }

  public updateFoldState(folded: boolean): void {
    this.isFolded$.next(folded);
  }

  public getFoldState(): Observable<boolean> {
    return this.isFolded$.asObservable();
  }
}
