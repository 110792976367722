import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash-es';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { domain } from '../../../environments/domain';
import { environment } from '../../../environments/environment';
import { SessionProvider } from '../../providers/session.provider';
import { UserRole } from '../../shared/models/user-role.enum';
import { AlgoliaHit } from '../../shared/services/algolia/algolia-hit.model';
import { AlgoliaTag } from '../../shared/services/algolia/algolia-tag.enum';
import { AlgoliaService } from '../../shared/services/algolia/algolia.service';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AutocompleteDataSource } from '../../widgets/autocomplete/autocomplete-data-source.model';
import { AutocompleteOptions } from '../../widgets/autocomplete/autocomplete-options.model';
import { BeforeRedirect } from '../../widgets/redirect/redirect.model';
import { RedirectService } from '../../widgets/redirect/redirect.service';
import { SearchBarResult } from './search-bar-result.model';

@Component({
  selector: 'cim-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  private query?: string;
  private tagTranslations: { [key: string]: string } = {};

  public moreResultsLabel?: string;
  public datasource!: AutocompleteDataSource<SearchBarResult>;
  public options!: AutocompleteOptions;
  public isAdminOrImpersonated?: boolean;
  // Constructor ---------------------------------------------------------------
  constructor(
    private translate: TranslateService,
    private algolia: AlgoliaService,
    private redirectService: RedirectService,
    private authService: AuthService,
    private sessionProvider: SessionProvider
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(user => {
      this.isAdminOrImpersonated = user.role === UserRole.ADMIN || user.isImpersonated;
    });
    this.translate.get([
      'SEARCH.MORE_RESULTS',
      'SEARCH.ARTICLE_TAG',
      'SEARCH.SOFTWARE_TAG',
      'SEARCH.RESOURCE_TAG',
      'SEARCH.PROMOTED_TAG',
      'SEARCH.COMPARISON_TAG',
      'SEARCH.TOPIC_TAG',
      'SEARCH.PLACEHOLDER'
    ]).subscribe(translations => {
      this.tagTranslations = {
        ...translations['SEARCH.ARTICLE_TAG'],
        ...translations['SEARCH.SOFTWARE_TAG'],
        ...translations['SEARCH.RESOURCE_TAG'],
        ...translations['SEARCH.PROMOTED_TAG'],
        ...translations['SEARCH.COMPARISON_TAG'],
        ...translations['SEARCH.TOPIC_TAG'],
      };

      this.moreResultsLabel = translations['SEARCH.MORE_RESULTS'];

      this.datasource = {
        data: query => {
          let hits$: Observable<Array<SearchBarResult>> = of([]);
          if (query) {
            hits$ = this.loadHits(query, 9);
          }
          return hits$;
        }
      };

      this.options = new AutocompleteOptions({
        placeholder: translations['SEARCH.PLACEHOLDER'],
        displayedHitsSize: 9
      });
    });
  }

  private loadHits(query: string, hitsPerPage: number): Observable<Array<SearchBarResult>> {
    const algoliaIndex = environment.algolia.indexPrefix + domain.algoliaIndexSuffix;
    const session = this.sessionProvider.getSession()

    return from<Observable<Array<SearchBarResult>>>(
      this.algolia
        .getIndex(algoliaIndex)
        .search(query, {
          hitsPerPage, page: 0,
          highlightPreTag: '<b>',
          highlightPostTag: '</b>',
          clickAnalytics: true,
          analytics: !this.isAdminOrImpersonated,
          enablePersonalization: true,
          userToken: session.uniqueId
        })
        .then(({
          hits,
          queryID
        }: { hits: Array<AlgoliaHit>, page: number, nbPages: number, queryID: string }) => {
          return hits.map((
            hit,
            position
          ) => {
            return new SearchBarResult({
              label: hit._highlightResult.title.value,
              url: hit.url,
              tag: this.translateTag(hit.tag),
              beforeRedirect: this.createBeforeRedirect(
                algoliaIndex,
                queryID,
                [hit.objectID],
                [position + 1],
                session.uniqueId
              )
            });
          });
        })
    );

  }

  private translateTag(tag: AlgoliaTag): string {
    switch (tag) {
      case AlgoliaTag.RESOURCE:
        return this.tagTranslations['SEARCH.RESOURCE_TAG'];
      case AlgoliaTag.ARTICLE:
        return this.tagTranslations['SEARCH.ARTICLE_TAG'];
      case AlgoliaTag.SOFTWARE:
        return this.tagTranslations['SEARCH.SOFTWARE_TAG'];
      case AlgoliaTag.CATEGORY:
        return this.tagTranslations['SEARCH.CATEGORY_TAG'];
    }
  }

  public onQueryChanged(query: string): void {
    this.query = query;
  }

  public search(query?: string): void {
    if (!isEmpty(query)) {
      let url = `/${domain.paths.search}`;
      url = query ? `${url}?query=${query}` : url;
      this.redirectService.navigate(url);
    }
  }

  public seeMoreResults(): void {
    this.search(this.query);
  }

  private createBeforeRedirect(
    algoliaIndex: string,
    queryID: string,
    objectIDs: Array<string>,
    positions: Array<number>,
    userToken: string
  ): BeforeRedirect {
    if (!this.isAdminOrImpersonated) {
      const data = {
        event: 'click',
        index: algoliaIndex,
        eventName: 'Click',
        queryID,
        objectIDs,
        positions,
        userToken
      };
      return _ => this.algolia.sendEvent(data).pipe(
        catchError(err => {
          console.error(err);
          return of(undefined);
        }));
    }
    return _ => of(undefined);
  }
}
