<div class="software-section-tile" [ngClass]="stateClass" [cimRedirect]="tileLink"
  [navigationParams]="tileNavigationParams">
  <img class="highlight-image" loading="lazy" [src]="software?.image?.url" [alt]="imageAlt">
  <div class="software-section-tile-content">
    <div class="software-section-tile-header">
      <div class="software-section-tile-caption">
        <span class="h3 software-section-tile-name">{{software?.name}}</span>
        <ic-icon class="external" *ngIf="software?.cta?.url?.length" [title]="software?.cta?.label" icon="open-in-new">
        </ic-icon>
      </div>
      <div class="software-section-tile-sub-caption cim-caption">
        <div class="software-section-tile-rating">
          <cim-rating [rating]="software?.rating || 0"></cim-rating>
        </div>
        <div>{{software?.reviewsLabel}}</div>
      </div>
      <div class="software-section-tile-category cim-caption">{{software?.softwareCategoryName}}</div>
    </div>
    <div class="software-section-tile-description">
      <span>{{software?.description}}</span>
    </div>
    <div class="software-section-tile-footer">
      <a [cimRedirect]="software?.redirection?.url" [navigationParams]="software?.redirection?.navigationParams"
        class="software-section-tile-redirection cim-hyperlink-sans accent underline">{{software?.redirection?.label}}</a>
    </div>
  </div>
</div>