<ng-content /> <!-- Fix missing hydratation anchor on ng-template with ngTemplateOutlet (see https://github.com/angular/angular/issues/50543#issuecomment-1572900259) -->
<div class="select" [ngClass]="{'active': toggle}" (click)="toggle = !toggle">
  <label>
    <ng-container *ngIf="(labelTemplateRef$ | async) as labelTemplateRef; else labelDefault">
      <ng-template [ngTemplateOutlet]="labelTemplateRef">
      </ng-template>
    </ng-container>

    <ng-template #labelDefault>
      <h4 class="select-title" [cimRedirect]="redirectUrl">{{label}}</h4>
    </ng-template>

    <ng-container *ngIf="hasToggle">
      <ic-icon class="closed" icon="chevron-down"></ic-icon>
      <ic-icon class="opened" icon="chevron-up"></ic-icon>
    </ng-container>
  </label>

  <div class="options" [ngStyle]="position">
    <div class="tpl">
      <ng-container *ngIf="(contentTemplateRef$ | async) as contentTemplateRef; else contentDefault">
        <ng-template [ngTemplateOutlet]="contentTemplateRef">
        </ng-template>
      </ng-container>

      <ng-template #contentDefault>
        <div class="option" *ngFor="let option of options">
          {{option}}
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="backdrop" [ngClass]="{'active': toggle}" (click)="toggle = false" (auxclick)="toggle = false"></div>
