import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NewsletterService } from './newsletter.service';

@Component({
  selector: 'cim-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {

  errorOnSubmit = false;
  validationError = false;
  pendingValidation = false;
  success = false;

  contact: { email: string } = { email: '' };

  newsletterForm = new FormGroup({
    email: new FormControl(this.contact.email, [
      Validators.required,
      Validators.email
    ])
  });

  @Input()
  public placeholder = '';
  @Input()
  public title = '';
  @Input()
  public description = '';
  @Input()
  public buttonLabel = '';
  @Input()
  public emailRequiredError = '';
  @Input()
  public emailInvalidError = '';
  @Input()
  public submitError = '';
  @Input()
  public style: 'light' | 'strong' = 'strong';

  constructor(private newsletterService: NewsletterService) {
  }

  get email() {
    return this.newsletterForm.get('email');
  }

  onkeyUp(): void {
    this.validationError = false;
    this.errorOnSubmit = false;
    this.success = false;
  }

  onSubmit(): void {
    this.pendingValidation = true;
    const isValid = this.newsletterForm.valid;
    this.newsletterForm.get('email')?.disable();
    if (isValid) {
      this.newsletterService.submit(this.newsletterForm.get('email')?.value as string)
        .pipe(
          catchError(error => {
            this.errorOnSubmit = true;
            this.pendingValidation = false;
            this.newsletterForm.get('email')?.enable();
            return throwError(`Error while trying to submit email: ${JSON.stringify(error)}`);
          }
          )
        ).subscribe(_ => {
          this.success = true;
          this.errorOnSubmit = false;
          this.validationError = false;
          this.pendingValidation = false;
        });
    } else {
      this.newsletterForm.get('email')?.enable();
      this.validationError = true;
      this.pendingValidation = false;
    }
  }
}
