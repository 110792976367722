<div class="footer">
  <img loading="lazy" class="arrows" src="../../assets/images/footer-arrows.svg" alt="footer-arrows">
  <img loading="lazy" class="jump" src="../../assets/images/footer-jump.svg" alt="footer-jump">
  <div class="container">
    <div class="links">
      <img loading="lazy" id="logo" src="assets/images/logo-Appvizer-white.webp" alt="appvizer logo" />
      <div class="cim-caption" *ngFor="let link of mainLinks">
        <div>
          <a [cimRedirect]="link.url" class="default alt">
            {{link.translateKey | translate}}
          </a>
        </div>
      </div>
    </div>

    <div *ngFor="let part of parts; let last=last;" class="links">
      <h3>{{part.title | translate}}</h3>
      <ng-container *ngFor="let link of part.links">
        <a [cimRedirect]="link.url" class="default" [ngClass]="{'cim-caption alt': link.type !== 'button',
          'button steel': link.type === 'button'}">{{link.translateKey | translate}}</a>
      </ng-container>

      <div id="current-domain" *ngIf="last">
        <cim-select [position]="hreflangOptionsPosition">
          <ng-template #labelTemplate>
            <span class="lang cim-caption">{{'FOOTER.LANGUAGE' | translate}}</span>
            <span>{{domain | translate}}</span>
          </ng-template>
          <ng-template #contentTemplate>
            <div class="href-lang" *ngFor="let otherDomain of domains" [cimRedirect]="otherDomain.url">
              <span>{{otherDomain.translateKey | translate}} </span>
            </div>
          </ng-template>
        </cim-select>
      </div>
    </div>

  </div>
</div>
