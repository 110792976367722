import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GtmTrackerService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  public enableTracking(): void {
    //  Build GTM script
    if (environment.gtmTrackingCode) {
      // Add script tag in head
      const gtmScript = this.document.createElement('script');
      gtmScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl${environment.gtmParams ? '+ \'' + environment.gtmParams + '\'' : ''};f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${environment.gtmTrackingCode}');`;
      this.document.head.appendChild(gtmScript);
      // Add noscript tag in body
      const gtmNoScript = this.document.createElement('noscript');
      const gtmNoScriptIframe = this.document.createElement('iframe');
      gtmNoScriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmTrackingCode}${environment.gtmParams}`;
      gtmNoScriptIframe.width = '0';
      gtmNoScriptIframe.height = '0';
      gtmNoScriptIframe.style.display = 'none';
      gtmNoScriptIframe.style.visibility = 'hidden';
      gtmNoScript.appendChild(gtmNoScriptIframe);
      this.document.body.appendChild(gtmNoScript);
    }
  }
}
