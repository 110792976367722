import { Directive, ElementRef, Input } from '@angular/core';
import { DataAttribute } from './data-attribute.model';

@Directive({
  selector: '[cimDataAttributes]'
})
export class DataAttributesDirective {
  @Input('cimDataAttributes')
  set dataAttributes(dataAttributes: DataAttribute[] | undefined) {
    if (dataAttributes?.length) {
      dataAttributes
        .filter(({ key, value }) => key?.length && value !== undefined)
        .forEach(({ key, value }) => this.el.nativeElement.setAttribute(`data-${key}`, value));
    }
  }

  constructor(
    private el: ElementRef
  ) {
  }
}
