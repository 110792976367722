<div class="newsletter-container {{style}}">
  <h3 class="title" [ngClass]="{'cim-sub-title-sans regular' : style === 'light'}">{{title}}</h3>
  <p class="description" [ngClass]="{'cim-body small' : style === 'light'}">{{description}}</p>

  <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">

    <input type="email" class="newsletter-input" [placeholder]="placeholder" formControlName="email"
      (onkeyup)="onkeyUp()">

    <button class="newsletter-button" *ngIf="!success && !pendingValidation" type="submit"
      [disabled]="success">{{buttonLabel}}</button>
    <button class="newsletter-button success" *ngIf="success" type="button" [disabled]="success">
      <ic-icon class="check" icon="check"></ic-icon>
    </button>
    <button class="newsletter-button pending" *ngIf="pendingValidation" type="button" [disabled]="pendingValidation">
      <ic-icon class="loading" icon="loading"></ic-icon>
    </button>
  </form>
</div>
<div class="newsletter-error">
  <div *ngIf="errorOnSubmit || validationError">
    <div *ngIf="email?.errors?.['required']">{{emailRequiredError}}</div>
    <div *ngIf="email?.errors?.['email']">
      {{emailInvalidError}}
    </div>
    <div *ngIf="errorOnSubmit">
      {{submitError}}
    </div>
  </div>
</div>