import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, ComponentRef, EventEmitter, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogContentDirective } from './dialog-content.directive';
import { DialogContent } from './dialog-content.model';
import { DialogService } from './dialog.service';

@Component({
  selector: 'cim-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  private readonly disableScrollClass = 'disable-scroll';

  @ViewChild(DialogContentDirective, { static: true })
  dialogContent!: DialogContentDirective;

  hidden$ = new BehaviorSubject(true);
  maxWidth?: string;
  maxHeight?: string;

  /**
   * Will store all the loaded componentRef so they can be reused if the dialog is closed & reopened
   */
  private componentRefById: { [id: string]: ComponentRef<any> } = {};

  private displayedContent: DialogContent | undefined;

  private body?: HTMLBodyElement;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private readonly dialogService: DialogService,
    private breakpointObserver: BreakpointObserver
  ) {
    const elements = document?.getElementsByTagName('body');
    if (elements?.length) {
      this.body = elements[0];
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.displayedContent && this.isCurrentWidthDisabled(this.displayedContent)) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.dialogService.content.subscribe(content => {
      if (
        content &&
        !this.isCurrentWidthDisabled(content)
      ) {
        this.body?.classList?.add(this.disableScrollClass);
        this.loadContent(content);

        const componentRef = content.stateId ? this.componentRefById[content.stateId] : undefined;

        if (content.callbacks?.onOpen && componentRef) {
          // push at the end of call stack to be sure the component is displayed
          setTimeout(() => content?.callbacks?.onOpen(componentRef.instance));
        }

        this.hidden$.next(false);

      } else {
        this.body?.classList?.remove(this.disableScrollClass);
        this.resetContent();
        this.hidden$.next(true);
      }

    });
  }

  /**
   * Load the {@param content} inside the dialog.
   *
   * Maybe by
   *  - reinjecting a previous component if {@see content.stateId} is filled and in {@see this.componentRefById}
   *  - creating the component based on the {@param content}
   */
  private loadContent(content: DialogContent): void {
    this.displayedContent = content;
    this.maxWidth = content.maxWidth;
    this.maxHeight = content.maxHeight;

    const alreadyRegisteredComponent = content.stateId ? this.componentRefById[content.stateId] : null;
    if (alreadyRegisteredComponent) {
      // just re-insert the component's view inside the viewContainerRef
      this.dialogContent.viewContainerRef.insert(alreadyRegisteredComponent.hostView);
      return;
    }

    // at this stage we need to create the component
    const componentRef = this.dialogContent.viewContainerRef.createComponent(content.component);

    // inject the inputs into the component
    Object.entries(content.inputs || {})
      .forEach(([inputKey, inputValue]) => componentRef.instance[inputKey] = inputValue);

    // listen for outputs in the component to propagate their events as configured
    Object.entries(content.outputs || {})
      .forEach(([outputKey, outputValue]) => {
        const output = componentRef.instance[outputKey];
        if (output && output instanceof EventEmitter) {
          output.subscribe(val => outputValue(val));
        }
      });

    if (content.stateId) {
      // register the componentRef so it will be re-inserted as is if the dialog is closed & reopened
      this.componentRefById[content.stateId] = componentRef;
    }
  }

  /**
   * Reset the content of the dialog by removing or detaching its view (based on
   * the fact that the {@see this.displayedContent} has a stateId or not)
   */
  private resetContent(): void {
    if (!this.dialogContent.viewContainerRef.get(0) || !this.displayedContent) {
      // nothing is displayed in the dialog, nothing to do
      return;
    }

    if (this.displayedContent.stateId) {
      // detach the view, it will be reinjected afterward if the dialog is reopened
      this.dialogContent.viewContainerRef.detach(0);
    } else {
      // no state defined, we can remove it
      this.dialogContent.viewContainerRef.remove(0);
    }

    this.displayedContent = undefined;
  }

  /**
   * Checks whether the width of the window is disabled dictated by {@see this.displayedContent.disabledBreakpoints}
   */
  private isCurrentWidthDisabled(content: DialogContent): boolean {
    if (!content.disabledBreakpoints?.length) {
      return false;
    }

    return this.breakpointObserver.isMatched(content.disabledBreakpoints);
  }

  /**
   * Handle the click anywhere on the dialog component.
   *
   * Used to close the dialog if a click occurs outside the container
   */
  handleClick(event: any): void {
    if (event?.target?.classList?.contains('dialog')) {
      this.close();
    }
  }

  /**
   * Close the dialog
   */
  close(): void {
    const componentRef = this.displayedContent?.stateId ? this.componentRefById[this.displayedContent.stateId] : undefined;

    if (this.displayedContent?.callbacks?.onClose && componentRef) {
      this.displayedContent.callbacks.onClose(componentRef.instance);
    }

    this.dialogService.close();
  }
}
