<div class="search-bar">
  <cim-autocomplete [datasource]="datasource" [options]="options" (queryChanged)="onQueryChanged($event)"
    (enterPressed)="search($event)">
    <ng-template #iconTemplate>
      <ic-icon icon="magnify"></ic-icon>
    </ng-template>
    <ng-template #hitTemplate let-hit>
      <div class="hit" [cimRedirect]="hit.url" [beforeRedirect]="hit.beforeRedirect">
        <span [innerHTML]="hit.label"></span>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <a class="more-results button ghost primary" (click)="seeMoreResults()">{{moreResultsLabel}}</a>
    </ng-template>
  </cim-autocomplete>
</div>
