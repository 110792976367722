export interface AdDefinition {
  //  Ad external id property (should be used only if type is not defined or if it is for the menu ad)
  externalIdProperty?: string;
  //  Ad type (should be used only if externalIdProperty is not defined or if it is for the menu ad)
  type?: string;
  //  Ad selector
  selector: string;
  //  Ad breakpoint to be displayed
  breakpoint?: string;
  //  Ad callback when successfully loaded
  onStatusChanged?: (changes: Array<{ status: AdStatus, elementRef?: HTMLElement, link: string }>) => void;
}

export enum AdStatus {
  LOADED = 'LOADED',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR'
}

