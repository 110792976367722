import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { domain } from '../../../../environments/domain';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CimServerService {

  constructor(private http: HttpClient) { }

  get<T>(
    path: string,
    options?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      context?: HttpContext;
      observe?: 'body';
      params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }): Observable<T> {
    return this.http.get<T>(environment.serverUrl[domain.id] + path, options);
  }

  post<T>(
    path: string,
    body: any | null,
    options?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      context?: HttpContext;
      observe?: 'body';
      params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }): Observable<T> {
    return this.http.post<T>(environment.serverUrl[domain.id] + path, body, options);
  }
}
