export const environment = {
  production: false,
  enableTransferStateForTranslations: false,
  facebook: [
    {
      content: '429078460823325',
    },
    {
      id: 'meta-fb-admin-Colin',
      content: '100000961422441',
    },
    {
      id: 'meta-fb-admin-Fabien',
      content: '100012648760275',
    },
  ],
  zapierWebhook: 'https://hooks.zapier.com/hooks/catch/1757401/bbsgq38',
  publicGatewayPublicURL: 'https://sandbox.api.appvizer.net/graphql',
  publicGatewayPrivateURL:
    'http://public-gateway-sandbox-helm-chart.public-gateway-130-sandbox:3000/graphql',
  reviewsCenterURL: 'https://sandbox.reviews.appvizer.net/graphql',
  leadsCenterURL: 'https://sandbox.leads.appvizer.net',
  contentCenterURL: 'https://sandbox.content.appvizer.net/graphql',
  adsCenterURL: {
    graphql: 'https://sandbox.regie.appvizer.net/api/graphql',
    script:
      'https://sandbox.static.regie.appvizer.net/scripts/appvizer-promoted.js',
  },
  hubspot: {
    formsScriptURL: 'https://js.hsforms.net/forms/embed/v2.js',
    portalId: '7403577',
  },
  gtmTrackingCode: 'GTM-KCNQ5TL',
  gtmParams:
    '&gtm_auth=DnwhXCrJragh4CI-304-cA&gtm_preview=env-27&gtm_cookies_win=x',
  trackingAppvizerURL: 'https://sandbox.ariadne.appvizer.net',
  translationsURL: 'https://pending.i18n.appvizer.net',
  aiAbTestServerUrl: 'https://sandbox.aireco.appvizer.net',
  /** Keys should be the domain ids from {@see domain.*.ts} */
  serverUrl: {
    BR: 'https://br.sandbox.appvizer.net',
    COM: 'https://com.sandbox.appvizer.net',
    DE: 'https://de.sandbox.appvizer.net',
    ES: 'https://es.sandbox.appvizer.net',
    FR: 'https://fr.sandbox.appvizer.net',
    IT: 'https://it.sandbox.appvizer.net',
    UK: 'https://uk.sandbox.appvizer.net',
  } as { [domainId: string]: string },
  algolia: {
    applicationID: 'Y57GPAG1OO',
    apiKey: 'f1d597240e3d817fbf5e5e37719d8e3c',
    insights: 'https://insights.algolia.io',
    indexPrefix: 'sandbox_',
  },
  domains: {
    FR: {
      urls: {
        home: 'https://fr.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
    ES: {
      urls: {
        home: 'https://es.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
    DE: {
      urls: {
        home: 'https://de.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
    IT: {
      urls: {
        home: 'https://it.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
    UK: {
      urls: {
        home: 'https://uk.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
    COM: {
      urls: {
        home: 'https://com.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
    BR: {
      urls: {
        home: 'https://br.sandbox.appvizer.net',
      },
      hubspot: {
        contactFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
        resourceFormId: '6ebc00e2-b15e-4f77-9f09-19321f6ce00c',
      },
    },
  } as { [_: string]: any },
};
