import { Component, Input } from '@angular/core';
import { DataAttribute } from '../../../widgets/data-attributes/data-attribute.model';
import { DataAttributesService } from '../../../widgets/data-attributes/data-attributes.service';
import { Image } from '../../models/image.model';
import { UIStateComponent } from '../../models/ui-state/ui-state.component';
import { HeadlineTile } from './headline-tile.model';

@Component({
  selector: 'cim-headline-tile',
  templateUrl: './headline-tile.component.html',
  styleUrls: ['./headline-tile.component.scss']
})
export class HeadlineTileComponent extends UIStateComponent {
  public stateClass = 'success';

  public url!: string;
  public title!: string;
  public titleClass!: string;
  public backgroundPicture?: string;
  public coverPicture!: Image;
  public tagLabel!: string;
  public summary!: string;
  public dataAttributes?: DataAttribute[];

  @Input()
  set headline(value: HeadlineTile | undefined) {
    if (value) {
      this.url = value.url;
      this.title = value.title;
      this.titleClass = value.titleClass || 'cim-title';
      this.backgroundPicture = value.backgroundPicture;
      this.coverPicture = value.coverPicture;
      this.tagLabel = value.tagLabel;
      this.summary = value.summary;

      this.dataAttributes = this.dataAttributesService.generateArticleDataAttributes(value);
    }
  }

  constructor(private readonly dataAttributesService: DataAttributesService) {
    super();
  }

  protected onLoad(): void {
    this.stateClass = 'load';
  }

  protected onSuccess(): void {
    this.stateClass = 'success';
  }
}
