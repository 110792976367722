import { Inject, Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { get, isEmpty } from 'lodash-es';
import { Script } from './script.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  private scriptSet: { [key: string]: Script } = {};

  isLoaded(scriptId: string): boolean {
    return get(this.scriptSet, [scriptId, 'loaded'], false);
  }

  addScripts(scripts?: Array<Script>): Observable<Array<Script>> {
    scripts = scripts || [];

    let result: { scriptSet: { [key: string]: Script }, loadingScripts: Array<string> } = {
      scriptSet: this.scriptSet,
      loadingScripts: []
    };

    //  Following reducer mutates scriptSet if a script is missing
    result = scripts.reduce((acc, script) => {
      if (!acc.scriptSet[script.id]) {
        acc.scriptSet[script.id] = {
          id: script.id,
          src: script.src,
          async: script.async,
          defer: script.defer,
          tag: script.tag,
          insertTag: script.insertTag,
          loaded: false
        };

        if (!script.loaded) {
          acc.loadingScripts.push(script.id);
        }
      }

      return acc;
    }, result);

    return isEmpty(result.loadingScripts) ? of([]) : this.load(result.loadingScripts);
  }

  load(scripts: Array<string>): Observable<Array<Script>> {
    const observables: Array<Observable<any>> = scripts.map(script => {
      return this.loadScript(script);
    });

    return isEmpty(observables) ? of([]) : forkJoin(observables);
  }

  loadScript(id: string): Observable<Script> {
    return new Observable(observer => {
      if (!this.scriptSet[id].loaded) {
        const script = this.document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.src = this.scriptSet[id].src;
        script.async = get(this.scriptSet, [id, 'async'], false);
        script.defer = get(this.scriptSet, [id, 'defer'], false);
        if ((script as any).readyState) {  // IE
          (script as any).onreadystatechange = () => {
            if ((script as any).readyState === 'loaded' || (script as any).readyState === 'complete') {
              (script as any).onreadystatechange = null;
              this.scriptSet[id].loaded = true;
              observer.next(this.scriptSet[id]);
              observer.complete();
            }
          };
        } else {
          script.onload = () => {
            this.scriptSet[id].loaded = true;
            observer.next(this.scriptSet[id]);
            observer.complete();
          };
        }
        script.onerror = (error: any) => observer.error(error);
        const targetElement = this.document.getElementsByTagName(get(this.scriptSet, [id, 'tag'], 'head'))[0];
        const action = get(this.scriptSet, [id, 'insertTag'], 'appendChild');
        switch (action) {
          case 'appendChild':
          case 'prepend':
            targetElement[action](script);
            break;
          case 'insertBefore':
            if (targetElement.parentNode) {
              targetElement.parentNode[action](script, targetElement);
            }
            break;
        }
      } else {
        observer.next(this.scriptSet[id]);
        observer.complete();
      }
    });
  }
}
