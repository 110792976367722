import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { domain } from '../environments/domain';
import { Domain, domains } from './shared/models/domain.enum';

export function appInitializerFactory(translate: TranslateService): () => Observable<any> {
  return () => {
    translate.setDefaultLang('en-US');

    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      return translate.use(getOverridenDomain() || domain.isoLocale);
    }
    return translate.use(domain.isoLocale);
  };
}

function getOverridenDomain(): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  const overridenDomainId = searchParams.get('domain')?.toUpperCase();
  if (!overridenDomainId) {
    return null;
  }
  const domainConfig = domains[overridenDomainId as keyof typeof Domain];
  if (!domainConfig) {
    return null;
  }

  return domainConfig.isoLocale;
}
