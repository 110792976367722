<article class="article-tile" [ngClass]="[direction,stateClass]" [cimRedirect]="url"
  [cimDataAttributes]="dataAttributes">
  <div class="image-container">
    <img class="background-picture" loading="lazy" *ngIf="backgroundPicture" [src]="backgroundPicture"
      alt="{{tagLabel}} background">
    <img loading="lazy" *ngIf="coverPicture  && coverPicture.url" [src]="coverPicture.url" [alt]="coverPicture.alt">
  </div>
  <div class="article-tile-content">
    <div class="article-tile-caption cim-caption" [ngClass]="{'hide': isCaptionHidden}">
      <div *ngIf="tagType" [ngClass]="['tag', tagType]">{{tagLabel}}</div>
      <div class="divider">&#8226;</div>
      <div class="date">{{date}}</div>
    </div>
    <div class="article-tile-text">
      <div class="clamp">
        <a [ngClass]="{'h4': class==='simplified', 'cim-sub-title': class!=='simplified'}"
          [cimRedirect]="url">{{title}}</a><br>
        <span class="article-tile-summary cim-caption">{{summary}}</span>
      </div>
    </div>
  </div>
</article>