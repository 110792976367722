import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NewsletterComponent} from './newsletter.component';
import { IconModule } from '@miles170/iconify-angular';


@NgModule({
  declarations: [
    NewsletterComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    ReactiveFormsModule
  ],
  exports: [
    NewsletterComponent
  ]
})
export class NewsletterModule {
}
