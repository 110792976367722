import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataAttributesModule } from '../../../widgets/data-attributes/data-attributes.module';
import { RedirectModule } from '../../../widgets/redirect/redirect.module';
import { HeadlineTileComponent } from './headline-tile.component';

@NgModule({
  declarations: [HeadlineTileComponent],
  imports: [
    CommonModule,
    RedirectModule,
    DataAttributesModule
  ],
  exports: [HeadlineTileComponent]
})
export class HeadlineTileModule { }
