import { ModuleWithProviders, NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { GraphQLService } from './shared/services/graphql/graphql.service';

@NgModule({
  imports: [ApolloModule],
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    graphQLService: GraphQLService
  ) {
    apollo.create(
      {
        link: httpLink.create({ uri: environment.publicGatewayPublicURL }),
        cache: new InMemoryCache(),
        defaultOptions: {
          query: { fetchPolicy: 'no-cache' },
          watchQuery: { fetchPolicy: 'no-cache' },
          mutate: { fetchPolicy: 'no-cache' },
        },
      },
      'public-gateway'
    );

    apollo.create(
      {
        link: httpLink.create({ uri: environment.publicGatewayPrivateURL }),
        cache: new InMemoryCache(),
        defaultOptions: {
          query: { fetchPolicy: 'no-cache' },
          watchQuery: { fetchPolicy: 'no-cache' },
          mutate: { fetchPolicy: 'no-cache' },
        },
      },
      'public-gateway-private'
    );

    apollo.create(
      {
        link: httpLink.create({ uri: environment.adsCenterURL.graphql }),
        cache: new InMemoryCache(),
      },
      'ads-center'
    );

    apollo.create(
      {
        link: httpLink.create({ uri: environment.reviewsCenterURL }),
        cache: new InMemoryCache(),
      },
      'reviews-center'
    );

    graphQLService.handleRouterEvents();
  }

  static forChild(): ModuleWithProviders<GraphQLModule> {
    return {
      ngModule: GraphQLModule,
    };
  }
}
