<ng-content />
<!-- Fix missing hydratation anchor on ng-template with ngTemplateOutlet (see https://github.com/angular/angular/issues/50543#issuecomment-1572900259) -->
<input
        type="checkbox"
        class="mobile-navigation-menu-checkbox"
        id="mobile-navigation-menu-toggle"
      />
<label for="mobile-navigation-menu-toggle" class="mobile-menu-toogle-label">
  <ic-icon class="navigation-menu-icon open" icon="menu"></ic-icon>
  <ic-icon class="navigation-menu-icon close" icon="close"></ic-icon>
</label>
<nav class="navigation-menu">
  <a class="no-link-style navigation-menu-item" *ngFor="let navigationItem of menu?.highlightedCategories" href="{{navigationItem.magazinePath}}">
    {{navigationItem.name}}
  </a>
  <div class="navigation-menu-item not-highlighted-categories">
    <p class="not-highlighted-categories-title">
      {{'NAVIGATION.ACTIVITY_AREA' | translate}}
      <ic-icon class="icon" icon="chevron-down"></ic-icon>
    </p>
    <div class="not-highlighted-categories-list">
      <a class="no-link-style  not-highlighted-category-header" *ngFor="let navigationItem of menu?.notHighlightedCategories" href="{{navigationItem.magazinePath}}">
        {{navigationItem.name}}
      </a>
    </div>
  </div>
</nav>
