import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { DialogContentDirective } from './dialog-content.directive';
import { DialogComponent } from './dialog.component';

@NgModule({
  declarations: [
    DialogContentDirective,
    DialogComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    DialogComponent
  ]
})
export class DialogModule {
}
