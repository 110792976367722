import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataAttributesDirective } from './data-attributes.directive';

@NgModule({
  declarations: [DataAttributesDirective],
  imports: [
    CommonModule
  ],
  exports: [DataAttributesDirective]
})
export class DataAttributesModule {
}
