import localeEs from '@angular/common/locales/es';

export const domain = {
  id: 'ES',
  locale: 'es_ES',
  isoLocale: 'es-ES',
  siteName: 'appvizer.es',
  ngLocale: localeEs as any,
  algoliaIndexSuffix: 'ES',
  externalUrls: {
    support: 'https://help.appvizer.com/es',
    facebook: 'https://www.facebook.com/appvizer.es',
    linkedin: 'https://www.linkedin.com/company/appvizer.es',
    twitter: 'https://twitter.com/appvizer_ES',
    youtube: 'https://www.youtube.com/c/Appvizer',
  },
  paths: {
    about: 'acerca-de',
    privacyPolicy: 'acerca-de/politica-de-privacidad',
    termsOfUse: 'acerca-de/condiciones-generales-de-uso',
    transparency: 'acerca-de/trasparencia',
    search: 'buscar',
    magazine: 'revista',
    compare: 'comparativo',
    softwareReviews: 'opiniones',
    softwareAlternatives: 'alternativas',
    hostedLandingPage: 'inscripcion',
    categories: 'categorias',
    author: 'autor',
    news: 'noticias',
    sitemaps: 'sitemaps',
    magazineSitemap: 'sitemaps/revista',
    selfRegistration: 'proveedores/inscripcion',
    login: 'iniciar-sesion',
    account: 'account/profil',
    editorServices: 'editor/services',
    editorStatistics: 'editor/statistics',
    editorOffers: 'editor/offers',
    editorLeads: 'editor/leads',
    boProspects: 'bo/prospects',
    boVendors: 'bo/vendors',
    boSalesProposal: 'bo/sales-proposal/manage',
    boUsers: '-bo/users',
    boServices: 'bo/services',
    boItems: '-bo/items',
    boContent: 'bo/content',
    boSoftwareGroups: '-bo/software-groups',
    boTests: '-bo/tests'
  }
};
