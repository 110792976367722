import localeIt from '@angular/common/locales/it';

export const domain = {
  id: 'IT',
  locale: 'it_IT',
  isoLocale: 'it-IT',
  siteName: 'appvizer.it',
  ngLocale: localeIt as any,
  algoliaIndexSuffix: 'IT',
  externalUrls: {
    support: 'https://help.appvizer.com/en',
    facebook: 'https://www.facebook.com/appvizer.it',
    linkedin: 'https://www.linkedin.com/company/appvizer-it',
    twitter: 'https://twitter.com/appvizer_it',
    youtube: 'https://www.youtube.com/c/Appvizer',
  },
  paths: {
    about: 'chi-siamo',
    privacyPolicy: 'chi-siamo/privacy',
    termsOfUse: 'chi-siamo/condizioni-generali-dutilizzo',
    transparency: 'chi-siamo/trasparenza',
    search: 'cerca',
    magazine: 'rivista',
    compare: 'comparativo',
    softwareReviews: 'recensioni',
    softwareAlternatives: 'alternative',
    hostedLandingPage: 'iscriviti',
    categories: 'categorie',
    author: 'autore',
    news: 'attualita',
    sitemaps: 'sitemaps',
    magazineSitemap: 'sitemaps/rivista',
    selfRegistration: 'partner/iscrizione',
    login: 'accesso',
    account: 'account/profilo',
    editorServices: 'editor/services',
    editorStatistics: 'editor/statistics',
    editorOffers: 'editor/offers',
    editorLeads: 'editor/leads',
    boProspects: 'bo/prospects',
    boVendors: 'bo/vendors',
    boSalesProposal: 'bo/sales-proposal/manage',
    boUsers: '-bo/users',
    boServices: 'bo/services',
    boItems: '-bo/items',
    boContent: 'bo/content',
    boSoftwareGroups: '-bo/software-groups',
    boTests: '-bo/tests'
  }
};
