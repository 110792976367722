import localeFr from '@angular/common/locales/fr';

export const domain = {
  id: 'FR',
  locale: 'fr_FR',
  isoLocale: 'fr-FR',
  siteName: 'appvizer.fr',
  ngLocale: localeFr as any,
  algoliaIndexSuffix: 'FR',
  externalUrls: {
    support: 'https://help.appvizer.com/fr',
    facebook: 'https://www.facebook.com/appvizer.fr',
    linkedin: 'https://www.linkedin.com/company/appvizer.fr',
    twitter: 'https://twitter.com/appvizer_FR',
    youtube: 'https://www.youtube.com/c/AppvizerFrance',
  },
  paths: {
    about: 'a-propos',
    privacyPolicy: 'a-propos/confidentialite',
    termsOfUse: 'a-propos/cgu',
    transparency: 'a-propos/transparence',
    search: 'recherche',
    magazine: 'magazine',
    compare: 'comparatif',
    softwareReviews: 'avis',
    softwareAlternatives: 'alternatives',
    hostedLandingPage: 'inscription',
    categories: 'categories',
    author: 'auteur',
    news: 'actualites',
    sitemaps: 'sitemaps',
    magazineSitemap: 'sitemaps/magazine',
    selfRegistration: 'partenaires/inscription',
    login: 'connexion',
    account: 'compte/profil',
    editorServices: 'editor/services',
    editorStatistics: 'editor/statistics',
    editorOffers: 'editor/offers',
    editorLeads: 'editor/leads',
    boProspects: 'bo/prospects',
    boVendors: 'bo/vendors',
    boSalesProposal: 'bo/sales-proposal/manage',
    boUsers: '-bo/users',
    boServices: 'bo/services',
    boItems: '-bo/items',
    boContent: 'bo/content',
    boSoftwareGroups: '-bo/software-groups',
    boTests: '-bo/tests'
  }
};
