<ng-content />
<!-- Fix missing hydratation anchor on ng-template with ngTemplateOutlet (see https://github.com/angular/angular/issues/50543#issuecomment-1572900259) -->
<div class="software-tile redirect" [ngClass]="stateClass" *ngIf="hasMainRedirect && state.status === UIStatus.SUCCESS"
  [cimRedirect]="mainUrl" [navigationParams]="{target:'_blank'}">
  <ng-container *ngTemplateOutlet="tile"></ng-container>
</div>

<div class="software-tile" [ngClass]="stateClass" *ngIf="!hasMainRedirect || state.status !== UIStatus.SUCCESS">
  <ng-container *ngTemplateOutlet="tile"></ng-container>
</div>

<ng-template #tile>
  <div class="header" [ngClass]="{'peek': softwareTile?.isPeek}">
    <div class="software-block">
      <img loading="lazy" class="logo" [src]="softwareTile?.logo?.url" [alt]="logoAlt">
      <div class="software-info">
        <div class="label">
          <span class="h3 name">{{softwareTile?.name}}</span>
          <ng-container *ngIf="hasMainRedirect">
            <ic-icon *ngIf="softwareTile?.cta?.hasPaidCTA && !softwareTile?.isPeek" class="icon cta-small"
              [title]="softwareTile?.cta?.label" icon="open-in-new"></ic-icon>
          </ng-container>
          <ng-container *ngIf="!hasMainRedirect">
            <ic-icon *ngIf="softwareTile?.cta?.hasPaidCTA && !softwareTile?.isPeek" class="icon cta-small"
              [cimRedirect]="softwareTile?.cta?.url" [navigationParams]="{target:'_blank'}"
              [title]="softwareTile?.cta?.label" icon="open-in-new"></ic-icon>
          </ng-container>
        </div>
        <cim-meta-review *ngIf="softwareTile?.useMetaReview; else rating" [rating]="softwareTile?.rating?.average"
          [label]="softwareTile?.rating?.reviewsCountLabel" [tooltipText]="softwareTile?.rating?.tooltipText">
        </cim-meta-review>
        <ng-template #rating>
          <div class="rating">
            <cim-rating [rating]="softwareTile?.rating?.average"></cim-rating>
            <p>{{softwareTile?.rating?.reviewsCountLabel}}</p>
          </div>
        </ng-template>
      </div>
    </div>
    <button *ngIf="softwareTile?.cta?.url" class="accent cta-big" [class.outline]="!softwareTile?.cta?.hasPaidCTA"
      [ngClass]="{'peek': softwareTile?.isPeek}" [cimRedirect]="softwareTile?.cta?.url"
      [navigationParams]="{target:'_blank'}">
      {{softwareTile?.cta?.label}}
      <ic-icon class="icon" *ngIf="softwareTile?.cta?.hasPaidCTA" [title]="softwareTile?.cta?.label" icon="open-in-new">
      </ic-icon>
    </button>
    <button *ngIf="softwareTile?.cta?.callback && !softwareTile?.cta?.url" class="accent" (click)="callback($event)">
      {{softwareTile?.cta?.label}}
    </button>
  </div>
  <div class="description" *ngIf="displayResume">{{softwareTile?.resume}}</div>
  <a class="more-information cim-hyperlink-sans accent underline" [cimRedirect]="softwareTile?.url"
    [navigationParams]="{target:'_blank'}" *ngIf="displayLinkLabel"
    [attr.data-no-redirect]='true'>{{softwareTile?.linkLabel}}</a>
</ng-template>

<div class="features-container" [class.opened]="!enableToggleFeatures || toggledFeatures"
  *ngIf="(softwareTile?.isPeek && (softwareTile?.features?.length || displayFeaturesTitle))">
  <div class="features-title" (click)="enableToggleFeatures && toggleFeatures($event)">
    <h4 *ngIf="displayFeaturesTitle">{{softwareTile?.featureLabel}}</h4>
    <ng-container *ngIf="enableToggleFeatures">
      <ic-icon class="closed" icon="chevron-down"></ic-icon>
      <ic-icon class="opened" icon="chevron-up"></ic-icon>
    </ng-container>
  </div>
  <div class="software-tile-features">
    <div class="software-tile-feature" [class.active]="feature.active" *ngFor="let feature of features">
      <ic-icon *ngIf="feature.active" class="check" icon="check"></ic-icon>
      <ic-icon *ngIf="!feature.active" class="close" icon="close"></ic-icon>
      <span class="body">{{feature?.label}}</span>
    </div>
  </div>
</div>