export class DropdownContext {
  opened = false;

  open(): void {
    this.opened = !this.opened;
  }

  close(): void {
    this.opened = !this.opened;
  }
}
