import { Injectable } from '@angular/core';
import { domain as currentDomain } from '../../../../environments/domain';
import { environment } from '../../../../environments/environment';
import { ArticleType } from '../../models/article-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public getHome(domain = currentDomain): string {
    return environment.domains[domain.id].urls.home;
  }

  public getMagazineUrl(domain = currentDomain): string {
    return `${this.getHome(domain)}/${domain.paths.magazine}`;
  }

  public getExternalUrl(
    urlKey: keyof typeof currentDomain.externalUrls,
    domain = currentDomain
  ): string {
    return domain.externalUrls[urlKey];
  }

  public getUrl(
    pathKey: keyof typeof currentDomain.paths,
    domain = currentDomain
  ): string {
    return this.removeMultipleSlashes(`${this.getHome(domain)}/${domain.paths[pathKey]}`);
  }

  public getArticleUrl(
    article: {
      path?: string,
      alias?: string,
      semanticType?: ArticleType
    },
    domain = currentDomain
  ): string {
    let url = `${this.getMagazineUrl(domain)}/`;
    if (article.path) {
      url += article.path;
    } else if (article.semanticType === ArticleType.NEWS && article.alias) {
      url += `${domain.paths.news}/${article.alias}`;
    }

    return this.removeMultipleSlashes(url);
  }

  public getAuthorUrl(
    authorAlias: string | null | undefined,
    domain = currentDomain
  ): string {
    return this.removeMultipleSlashes(`${this.getMagazineUrl(domain)}/${domain.paths.author}/${authorAlias}`);
  }

  public getSoftwareUrl(
    softwarePath: string | null | undefined,
    domain = currentDomain
  ): string {
    return this.removeMultipleSlashes(`${this.getHome(domain)}/${softwarePath || ''}`);
  }

  public getSoftwareAlternativesUrl(
    softwarePath: string | null | undefined,
    domain = currentDomain
  ): string {
    return `${this.getSoftwareUrl(softwarePath, domain)}/${domain.paths.softwareAlternatives}`;
  }

  public getDirectoryUrl(
    categoryPath: string | null | undefined,
    page?: number,
    domain = currentDomain
  ): string {
    return this.removeMultipleSlashes(`${this.getHome(domain)}/${categoryPath || ''}${!page || page <= 1 ? '' : `/${page}`}`);
  }

  public getMagazineCategoryUrl(
    categoryPath: string | null | undefined,
    domain = currentDomain
  ): string {
    return this.removeMultipleSlashes(`${this.getMagazineUrl(domain)}/${categoryPath || ''}`);
  }

  private removeMultipleSlashes(url: string): string {
    return url.replace(/([^:])(\/\/+)/g, '$1/');
  }
}
