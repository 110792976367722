import { DOCUMENT, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { domain } from '../environments/domain';
import { AdminButtonsService } from './admin-buttons/admin-buttons.service';
import { AB_TEST_SESSION_STORAGE_DATA, AB_TEST_SESSION_STORAGE_NAME } from './shared/ab-test.constants';
import { AdService } from './shared/services/ad/ad.service';
import { AppvizerGlobalTrackerService } from './shared/services/appvizer-tracker/appvizer-global-tracker.service';
import { PlatformService } from './shared/services/platform/platform.service';
import { GtmTrackerService } from './widgets/gtm-tracker/gtm-tracker.service';

@Component({
  selector: 'cim-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public isAdminButtonsDisplayed$ = new BehaviorSubject<boolean>(false);
  constructor(
    gtmTracker: GtmTrackerService,
    adminButtonsService: AdminButtonsService,
    platformService: PlatformService,
    adService: AdService,
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private appvizerGlobalTrackerService: AppvizerGlobalTrackerService,
  ) {

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    registerLocaleData(domain.ngLocale);
    document.documentElement.lang = domain.isoLocale;

    platformService.proceedOnce(
      'GTM',
      () => gtmTracker.enableTracking()
    );

    platformService.proceedOnce(
      platformService.KEYS.APPVIZER_PROMOTED,
      () => adService.loadScript()
    );

    adminButtonsService.isDisplayed$.subscribe(isAdminButtonsDisplayed => {
      this.isAdminButtonsDisplayed$.next(isAdminButtonsDisplayed);
    });

    if (isPlatformBrowser(platformId)) {
      sessionStorage.removeItem(AB_TEST_SESSION_STORAGE_NAME);
      sessionStorage.removeItem(AB_TEST_SESSION_STORAGE_DATA);
      adService.disableNativeAdsEvents();
    }

    document.addEventListener('contextmenu', event => this.onClick(event), true);
    document.addEventListener('auxclick', event => this.onClick(event), true);
    document.addEventListener('click', event => this.onClick(event), true);
  }

  // ------- Below this, the methods are for tracking V3 -------- //
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // This timeout allow the 'send' to be executed in the next event loop, to ensure that the DOM is fully written
      setTimeout(() =>
        this.appvizerGlobalTrackerService.send('PAGE_OPENED', { event: { timeStamp: performance.now() } }).subscribe(), 0);
    }
  }

  @HostListener('window:pagehide', ['$event'])
  onClose(event: Event): void {
    this.appvizerGlobalTrackerService.send('PAGE_CLOSED', { event }).subscribe();
  }

  onClick(event: Event): void {
    this.appvizerGlobalTrackerService.send('CLICK', { event }).subscribe();
  }
}
