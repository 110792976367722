import { Injectable } from '@angular/core';
import { DataAttribute } from './data-attribute.model';

@Injectable({
  providedIn: 'root'
})
export class DataAttributesService {

  private DATA_ATTRIBUTES_KEYS = {
    ARTICLE: {
      ALIAS: 'article-alias',
      SC_ID: 'article-sc-id',
      SC_NAME: 'article-sc-name',
      GC_ID: 'article-gc-id',
      GC_NAME: 'article-gc-name',
    },
    SOFTWARE: {
      ID: 'software-id',
      LOCALE_ID: 'software-locale-id',
      NAME: 'software-name',
      GC_ID: 'software-gc-id',
      GC_NAME: 'software-gc-name',
      SC_ID: 'software-sc-id',
      SC_NAME: 'software-sc-name',
      PROVIDER_ID: 'software-provider-id',
      HAS_CTA: 'software-has-cta',
    },
    CATEGORY: {
      TRACKING_ID: 'tracking-id'
    }
  };

  /**
   * Generate the data attributes corresponding to the given {@param article}.
   *
   * Once passed through the {@see DataAttributesDirective}, these will be injected in the HTML element's data attributes
   */
  public generateArticleDataAttributes(
    article: {
      alias?: string,
      category?: {
        id?: string | number,
        name?: string,
        parent?: {
          id?: string | number,
          name?: string,
        }
      }
    }
  ): DataAttribute[] {
    if (!article) {
      return [];
    }

    return [
      { key: this.DATA_ATTRIBUTES_KEYS.ARTICLE.ALIAS, value: article.alias },
      { key: this.DATA_ATTRIBUTES_KEYS.ARTICLE.SC_ID, value: article.category?.id },
      { key: this.DATA_ATTRIBUTES_KEYS.ARTICLE.SC_NAME, value: article.category?.name },
      { key: this.DATA_ATTRIBUTES_KEYS.ARTICLE.GC_ID, value: article.category?.parent?.id },
      { key: this.DATA_ATTRIBUTES_KEYS.ARTICLE.GC_NAME, value: article.category?.parent?.name }
    ];
  }

  /**
   * Generate the data attributes corresponding to the given {@param software}.
   *
   * Once passed through the {@see DataAttributesDirective}, these will be injected in the HTML element's data attributes
   */
  public generateSoftwareDataAttributes(
    software: {
      name?: string,
      id?: string | number,
      localeId?: string | number,
      gcId?: string | number,
      gcName?: string,
      scId?: string | number,
      scName?: string,
      providerId?: string | number,
      cta?: { hasPaidCTA?: boolean, url?: string }
    }
  ): DataAttribute[] {
    if (!software) {
      return [];
    }

    return [
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.ID, value: software.id },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.LOCALE_ID, value: software.localeId },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.NAME, value: software.name },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.GC_ID, value: software.gcId },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.GC_NAME, value: software.gcName },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.SC_ID, value: software.scId },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.SC_NAME, value: software.scName },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.PROVIDER_ID, value: software.providerId },
      { key: this.DATA_ATTRIBUTES_KEYS.SOFTWARE.HAS_CTA, value: software.cta?.hasPaidCTA && !!software.cta?.url?.length }
    ];
  }

  /**
   * Generate the data attributes corresponding to the given {@param category}.
   *
   * Once passed through the {@see DataAttributesDirective}, these will be injected in the HTML element's data attributes
   */
  public generateCategoryDataAttributes(
    category: {
      name?: string,
      humanReadableIdentifier?: string
    }
  ): DataAttribute[] {
    if (!category) {
      return [];
    }

    return [
      { key: this.DATA_ATTRIBUTES_KEYS.CATEGORY.TRACKING_ID, value: category.humanReadableIdentifier || category.name }
    ];
  }
}
