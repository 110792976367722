import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { of } from 'rxjs';
import { RedirectHelperService } from './redirect-helper.service';
import { BeforeRedirect, RedirectionExtras } from './redirect.model';
import { RedirectService } from './redirect.service';

@Directive({
  selector: '[cimRedirect]'
})
export class RedirectDirective {

  constructor(
    readonly redirect: RedirectService,
    private el: ElementRef,
    private redirectHelperService: RedirectHelperService
  ) { }

  private BLANK_TARGET = '_blank';
  private _url: string | undefined;

  @Input('cimRedirect')
  set url(url: string | undefined) {
    if (!url) {
      return;
    }
    this._url = url;
    const redirectType = this.redirectHelperService.computeRedirectType(url);
    if (redirectType) {
      this.el.nativeElement.setAttribute(`data-redirect-type`, redirectType);
    }
  }

  @Input() navigationParams?: RedirectionExtras;

  @Input() beforeRedirect?: BeforeRedirect;

  @HostListener('click', ['$event']) onClick($event: MouseEvent): boolean {
    $event.stopPropagation();
    $event.preventDefault();

    if (this._url) {
      let beforeRedirect$ = of(null);

      if (this.beforeRedirect) {
        beforeRedirect$ = this.beforeRedirect(this._url, this.navigationParams);
      }

      // Prevents the new tab opening to be blocked :
      // Open a new window if target is blank then pass it to the redirect service
      // ctrlKey is used to open the link in a new tab, same for metaKey for macOS
      let newWindow: Window | null;
      if (this.navigationParams?.target === this.BLANK_TARGET || $event.ctrlKey || $event.metaKey) {
        newWindow = window.open();
      }

      beforeRedirect$.subscribe(_ =>
        this.redirect.navigate(this.redirectHelperService.computeRedirectUrl(this._url as string), this.navigationParams, newWindow)
      );
    }

    return false;
  }

  @HostListener('auxclick', ['$event']) onAuxClick($event: MouseEvent): boolean {
    $event.stopPropagation();
    $event.preventDefault();

    if ($event.button === 1) {
      if (this._url) {
        let beforeRedirect$ = of(null);

        if (this.beforeRedirect) {
          beforeRedirect$ = this.beforeRedirect(this._url, this.navigationParams);
        }

        const newWindow = window.open();
        beforeRedirect$.subscribe(_ =>
          this.redirect.navigate(this.redirectHelperService.computeRedirectUrl(this._url as string), { ...this.navigationParams, target: '_blank' }, newWindow)
        );
      }
    }
    return false;
  }

  @HostBinding('href')
  get href(): string {
    return this._url || '#';
  }

  @HostBinding('attr.rel')
  get rel(): string | null {
    return this.navigationParams?.rel || null;
  }
}
