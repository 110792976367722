
export enum LinkType {
  BUTTON = 'button',
  ANCHOR = 'anchor'
}

export class Link {
  url!: string;
  type?: LinkType = LinkType.ANCHOR;
}
