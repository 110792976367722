import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { RedirectModule } from '../widgets/redirect/redirect.module';
import { AdminButtonsComponent } from './admin-buttons.component';
import { AdminButtonsService } from './admin-buttons.service';


@NgModule({
  declarations: [
    AdminButtonsComponent
  ],
  imports: [
    CommonModule,
    RedirectModule,
    IconModule
  ],
  exports: [
    AdminButtonsComponent
  ]
})
export class AdminButtonsModule {
  constructor(adminButtonsService: AdminButtonsService) {
    adminButtonsService.toggleAdministrationPanelOnNavigationEnd().subscribe();
  }
}
