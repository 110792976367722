import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { RedirectService } from '../widgets/redirect/redirect.service';
import { AdminButtonModel } from './admin-button.model';
import { AdminButtonsService } from './admin-buttons.service';

@Component({
  selector: 'cim-admin-buttons',
  templateUrl: './admin-buttons.component.html',
  styleUrls: ['./admin-buttons.component.scss']
})
export class AdminButtonsComponent implements OnInit {

  public isAction: boolean | undefined;
  public buttons$ = new BehaviorSubject<Array<AdminButtonModel>>([]);

  constructor(
    private readonly adminButtonsService: AdminButtonsService,
    private redirect: RedirectService
  ) { }

  ngOnInit(): void {
    this.adminButtonsService.buttons$
      .subscribe(buttons => this.buttons$.next(buttons));
  }

  handleClick(button: AdminButtonModel): void {
    if (typeof button.urlOrAction === 'function') {
      button.loading = true;
      button.disabled = true;
      button.urlOrAction().subscribe(
        () => {
          button.loading = false;
        },
        (error: Error) => {
          button.loading = false;
          button.error = error;
          return of(error);
        }
      );
    } else {
      this.redirect.navigate(button.urlOrAction, { target: '_blank' });
    }
  }
}
