<ng-content />
<!-- Fix missing hydratation anchor on ng-template with ngTemplateOutlet (see https://github.com/angular/angular/issues/50543#issuecomment-1572900259) -->
<div class="dropdown">
  <input id="dd-{{dropdownId}}" type="checkbox" class="dropdown-toggle" [checked]="context.opened">
  <label for="dd-{{dropdownId}}">
    <ng-container *ngIf="(labelTemplateRef$ | async) as labelTemplateRef; else labelDefault">
      <ng-template [ngTemplateOutlet]="labelTemplateRef" [ngTemplateOutletContext]="{$implicit: context}">
      </ng-template>
    </ng-container>

    <ng-template #labelDefault>
      <span class="h4 dropdown-title">
        <a class="primary default" [cimDataAttributes]="dataAttributes" [cimRedirect]="redirectUrl">{{label}}</a>
      </span>
    </ng-template>

    <ng-container *ngIf="hasToggle">
      <ic-icon class="closed" icon="chevron-down"></ic-icon>
      <ic-icon class="opened" icon="chevron-up"></ic-icon>
    </ng-container>
  </label>

  <div class="dropdown-content">
    <ng-container *ngIf="(contentTemplateRef$ | async) as contentTemplateRef; else contentDefault">
      <ng-template [ngTemplateOutlet]="contentTemplateRef" [ngTemplateOutletContext]="{$implicit: context}">
      </ng-template>
    </ng-container>

    <ng-template #contentDefault>
      <div class="dropdown-item" *ngFor="let option of options">
        {{option}}
      </div>
    </ng-template>
  </div>
</div>