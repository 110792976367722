import { DatePipe } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { DataAttribute } from '../../../widgets/data-attributes/data-attribute.model';
import { DataAttributesService } from '../../../widgets/data-attributes/data-attributes.service';
import { Image } from '../../models/image.model';
import { UIStateComponent } from '../../models/ui-state/ui-state.component';
import { ArticleTile } from './article-tile.model';

@Component({
  selector: 'cim-article-tile',
  templateUrl: './article-tile.component.html',
  styleUrls: ['./article-tile.component.scss']
})
export class ArticleTileComponent extends UIStateComponent {
  public stateClass = 'success';

  public url!: string;
  public title!: string;
  public summary!: string;
  public backgroundPicture?: string;
  public coverPicture!: Image;
  public tagLabel!: string;
  public tagType!: string;
  public date?: string;
  public direction: 'horizontal' | 'vertical' = 'horizontal';
  public isCaptionHidden = false;

  public dataAttributes?: DataAttribute[];

  @HostBinding('class') class!: string;

  @Input()
  set article(value: ArticleTile) {
    this.url = value?.url;
    this.title = value?.title;
    this.summary = value?.summary;
    this.backgroundPicture = value?.backgroundPicture;
    this.coverPicture = value?.coverPicture;
    this.tagLabel = value?.tagLabel;
    this.tagType = value?.tagType;
    this.class = value?.class || '';
    if (value?.date) {
      this.date = this.datePipe.transform(value.date, 'longDate') || undefined;
    }

    this.direction = value?.direction || 'horizontal';
    this.isCaptionHidden = value?.isCaptionHidden || false;

    this.dataAttributes = this.dataAttributesService.generateArticleDataAttributes(value);
  }

  constructor(
    private readonly datePipe: DatePipe,
    private readonly dataAttributesService: DataAttributesService
  ) {
    super();
  }

  protected onLoad(): void {
    this.stateClass = 'load';
  }

  protected onSuccess(): void {
    this.stateClass = 'success';
  }
}
