import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { isEmpty } from 'lodash-es';
import { domain } from '../../../../environments/domain';
import { environment } from '../../../../environments/environment';
import { HreflangDTO } from '../../models/hreflang.dto';
import { OpenGraph } from '../../models/open-graph.model';
import { UrlService } from '../url/url.service';

const CUSTOM_META_DATA_PREFIX = 'appvizer-';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private urlService: UrlService,
    @Inject(DOCUMENT) private dom: Document
  ) {
  }

  private appendHreflang(hreflang: HreflangDTO): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hreflang', hreflang.isoLocaleCode);
    link.setAttribute('href', hreflang.url);
    this.dom.head.appendChild(link);
  }

  private appendCanonicalUrl(url?: string): void {
    const canURL = url === undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canURL);
    this.dom.head.appendChild(link);
  }

  private appendJsonLd(jsonLd?: string): void {
    if (jsonLd) {
      const script: HTMLScriptElement = this.dom.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      script.textContent = jsonLd;
      this.dom.head.appendChild(script);
    }
  }

  buildJsonLdOrganization(): any {
    const jsonLdOrganization: any = {
      '@type': 'Organization',
      name: 'Appvizer',
      sameAs: [
        this.urlService.getExternalUrl('facebook'),
        this.urlService.getExternalUrl('twitter'),
        this.urlService.getExternalUrl('linkedin'),
        this.urlService.getExternalUrl('youtube')
      ]
    };

    jsonLdOrganization.url = environment.domains[domain.id].urls.home;
    jsonLdOrganization.logo = jsonLdOrganization.url + '/assets/images/icon.webp';

    return jsonLdOrganization;
  }

  setHrefLangs(hreflangs: Array<HreflangDTO>): void {
    hreflangs.forEach(hreflang => {
      this.appendHreflang(hreflang);
    });
  }

  setMetadata(metaTitle: string, metaDescription?: string): void {
    this.titleService.setTitle(metaTitle);

    if (!isEmpty(metaDescription)) {
      this.metaTagService.removeTag(`property='description'`);
      this.metaTagService.addTag({
        property: 'description',
        content: metaDescription as string
      });
    }
  }

  setCustomMetadata(metaName: string, metaContent: string, withPrefix = false): void {
    let name = metaName;
    if (withPrefix) {
      name = `${CUSTOM_META_DATA_PREFIX}${name}`;
    }
    this.metaTagService.addTag({
      name,
      content: metaContent
    });
  }

  setJsonLd(jsonLd: { [key: string]: any }): void {
    if (jsonLd) {
      this.appendJsonLd(
        JSON.stringify(Object.assign({
          '@context': 'https://schema.org'
        }, jsonLd))
      );
    }
  }

  setCanonicalUrl(canonical?: string): void {
    this.appendCanonicalUrl(canonical);
  }

  setOpenGraph(openGraph: OpenGraph): void {
    const tags: Array<MetaDefinition> = [
      { property: 'og:type', content: openGraph.type },
      { property: 'og:site_name', content: openGraph.siteName },
      { property: 'og:locale', content: openGraph.locale },
      { property: 'og:updated_time', content: openGraph.updatedTime },
      { name: 'twitter:card', content: openGraph.twitterCard },
      { name: 'twitter:site', content: openGraph.twitterSite },
      { name: 'twitter:creator', content: openGraph.twitterCreator },
      { name: 'description', content: openGraph.description },
      { property: 'og:title', content: openGraph.title },
      { property: 'og:url', content: openGraph.url },
      { property: 'og:description', content: openGraph.description },
      { name: 'twitter:title', content: openGraph.title },
      { name: 'twitter:description', content: openGraph.description }
    ];

    if (openGraph.image) {
      if (openGraph.image.url) {
        tags.push({ property: 'og:image', content: openGraph.image.url });
        tags.push({ property: 'twitter:image', content: openGraph.image.url });
      }

      if (openGraph.image.mimeType) {
        tags.push({ property: 'og:image:type', content: openGraph.image.mimeType });
      }

      if (openGraph.image.width) {
        tags.push({ property: 'og:image:width', content: openGraph.image.width });
      }

      if (openGraph.image.height) {
        tags.push({ property: 'og:image:height', content: openGraph.image.height });
      }

      if (openGraph.image.alt) {
        tags.push({ property: 'og:image:alt', content: openGraph.image.alt });
        tags.push({ property: 'twitter:image:alt', content: openGraph.image.alt });
      }
    }

    this.metaTagService.addTags(tags);

    openGraph.fbAppIds.forEach(fbAppId => {
      if (fbAppId.id) {
        this.metaTagService.addTag({ property: 'fb:app_id', id: fbAppId.id, content: fbAppId.content });
      } else {
        this.metaTagService.addTag({ property: 'fb:app_id', content: fbAppId.content });
      }
    });
  }

  getCustomMetaData(): Array<{ [key: string]: string }> {
    return Array.from(this.dom.querySelectorAll(`meta[name^=${CUSTOM_META_DATA_PREFIX}]`)).reduce((res: any, metaTag: Element) => {
      const property = metaTag.getAttribute('name')?.replace(CUSTOM_META_DATA_PREFIX, '');
      const metaDataValue = metaTag.getAttribute('content');
      if (property && metaDataValue) {
        res[property] = metaDataValue;
      }
      return res;
    }, {});
  }
}
