import { Component, OnInit } from '@angular/core';
import { domain } from '../../environments/domain';
import { Domain, domains } from '../shared/models/domain.enum';
import { LinkType } from '../shared/models/link.model';
import { UrlService } from '../shared/services/url/url.service';
import { Position } from '../widgets/select/position.model';
import { LocalizedLink, Part, SocialLink, SocialNetwork } from './footer.model';

@Component({
  selector: 'cim-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  mainLinks: Array<LocalizedLink> = [];
  socialLinks: Array<SocialLink> = [];
  parts: Array<Part> = [];
  domain!: string;
  domains: Array<LocalizedLink> = [];
  hreflangOptionsPosition: Position = { bottom: 0, right: 0 };

  constructor(
    private urlService: UrlService,
  ) {
  }

  ngOnInit(): void {
    this.domain = domain.id;

    this.mainLinks = [
      {
        translateKey: 'FOOTER.ABOUT',
        url: this.urlService.getUrl('about'),
      },
      {
        translateKey: 'FOOTER.LEGAL_NOTICE',
        url: this.urlService.getUrl('privacyPolicy'),
      },
      {
        translateKey: 'FOOTER.TERMS',
        url: this.urlService.getUrl('termsOfUse'),
      }
    ];

    this.socialLinks = [
      {
        socialNetwork: SocialNetwork.FACEBOOK,
        url: this.urlService.getExternalUrl('facebook'),
      },
      {
        socialNetwork: SocialNetwork.TWITTER,
        url: this.urlService.getExternalUrl('twitter'),
      },
      {
        socialNetwork: SocialNetwork.LINKEDIN,
        url: this.urlService.getExternalUrl('linkedin'),
      },
      {
        socialNetwork: SocialNetwork.YOUTUBE,
        url: this.urlService.getExternalUrl('youtube'),
      }
    ];

    this.parts = [
      {
        title: '',
        links: [
          {
            translateKey: 'FOOTER.SOFTWARE',
            url: this.urlService.getUrl('sitemaps'),
          },
          {
            translateKey: 'FOOTER.MAGAZINE_SITEMAP',
            url: this.urlService.getUrl('magazineSitemap'),
          },
          {
            translateKey: 'FOOTER.TRANSPARENCY',
            url: this.urlService.getUrl('transparency'),
          }
        ]
      },
      {
        title: 'FOOTER.PARTNERS',
        links: [
          {
            translateKey: 'FOOTER.EDITOR',
            url: this.urlService.getUrl('login'),
          },
          {
            translateKey: 'FOOTER.HELP_CENTER',
            url: this.urlService.getExternalUrl('support'),
          },
          {
            translateKey: 'REGISTER',
            url: this.urlService.getUrl('selfRegistration'),
            type: LinkType.BUTTON,
          }
        ]
      }
    ];

    const homeByDomain: { [domain in Domain]: string } = {
      [Domain.FR]: this.urlService.getHome(domains[Domain.FR]),
      [Domain.COM]: this.urlService.getHome(domains[Domain.COM]),
      [Domain.UK]: this.urlService.getHome(domains[Domain.UK]),
      [Domain.BR]: this.urlService.getHome(domains[Domain.BR]),
      [Domain.IT]: this.urlService.getHome(domains[Domain.IT]),
      [Domain.ES]: this.urlService.getHome(domains[Domain.ES]),
      [Domain.DE]: this.urlService.getHome(domains[Domain.DE]),
    };

    this.domains = [
      {
        translateKey: 'FR',
        url: homeByDomain.FR,
      },
      {
        translateKey: 'COM',
        url: homeByDomain.COM,
      },
      {
        translateKey: 'UK',
        url: homeByDomain.UK,
      },
      {
        translateKey: 'BR',
        url: homeByDomain.BR,
      },
      {
        translateKey: 'IT',
        url: homeByDomain.IT,
      },
      {
        translateKey: 'DE',
        url: homeByDomain.DE,
      },
      {
        translateKey: 'ES',
        url: homeByDomain.ES,
      }
    ];
  }

}
