import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostBinding, HostListener, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';

@Directive({
  selector: '[cimTooltip]'
})
export class TooltipDirective implements OnDestroy {
  private tooltip?: HTMLElement;
  private addedElements: HTMLElement[] = [];

  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private el: ElementRef<HTMLDivElement>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) protected readonly platformId: string,
  ) {
  }

  @Input('cimTooltip')
  tooltipText?: string;

  @Input()
  tooltipWidth!: string;

  @Input()
  canActivate?: (elementRef: ElementRef<HTMLDivElement>) => Observable<boolean>;

  @HostBinding('style.position')
  position = 'relative';

  @HostListener('mouseenter') onHover(): void {
    if (this.subscriptions.canActivate) {
      this.subscriptions.canActivate.unsubscribe();
    }

    const canActivate$ = this.canActivate ? this.canActivate(this.el) : of(true);

    this.subscriptions.canActivate = canActivate$.subscribe(canActivate => {
      if (canActivate) {
        this.tooltip = this.createTooltip();
        this.setTooltipPosition();
      }
    });
  }

  @HostListener('mouseleave') onLeave(): void {
    if (this.tooltip) {
      this.tooltip.remove();
    }
    this.addedElements.forEach(html => {
      html.remove();
    });
    this.addedElements = [];
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(subscription => subscription.unsubscribe());
  }

  private createTooltip(): HTMLElement {
    // Tooltip message box
    const tooltip = this.document.createElement('div');
    tooltip.classList.add('cim-tooltip', 'top', 'visible');
    tooltip.textContent = this.tooltipText ?? '';
    tooltip.style.width = this.tooltipWidth;

    // Tooltip arrow
    const arrow = this.document.createElement('div');
    arrow.classList.add('cim-tooltip-arrow');

    this.el.nativeElement.appendChild(tooltip);
    this.el.nativeElement.appendChild(arrow);
    this.addedElements?.push(arrow);
    return tooltip;
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any): void {
    this.setTooltipPosition();
  }

  private setTooltipPosition(): void {
    if (!this.tooltip) {
      return;
    }

    const tooltipBounds = this.tooltip.getBoundingClientRect();
    const containerBounds = this.el.nativeElement.getBoundingClientRect();
    if (tooltipBounds.right + 32 > this.document.documentElement.clientWidth) {
      this.tooltip.classList.replace('right', 'top');
      const leftCoordinates = Math.max(
        // Shifts the box to the left, if screen size decreases
        this.document.documentElement.clientWidth - (containerBounds.left + tooltipBounds.width + 32),
        -containerBounds.left + 16 // Prevents the toolbox to go out of the screen
      );
      this.tooltip.style.left = `${leftCoordinates}px`;
    } else if (containerBounds.right + tooltipBounds.width + 32 < this.document.documentElement.clientWidth) {
      this.tooltip.classList.replace('top', 'right');
      this.tooltip.style.left = '';
    }
  }
}
