import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly transferState: TransferState
  ) { }

  public getTranslation(lang: string): Observable<any> {
    if (!environment.enableTransferStateForTranslations) {
      return new TranslateHttpLoader(this.http, `${environment.translationsURL}/`, '.json').getTranslation(lang);
    }

    const key = makeStateKey<number>('transfer-translate-' + lang);
    const dataFromTransferState = this.transferState.get(key, null);

    return dataFromTransferState ?
      of(dataFromTransferState) :
      new TranslateHttpLoader(this.http, `${environment.translationsURL}/`, '.json').getTranslation(lang);
  }
}

export function translateBrowserLoaderFactory(
  httpClient: HttpClient,
  transferState: TransferState
): TranslateBrowserLoader {
  return new TranslateBrowserLoader(httpClient, transferState);
}
