import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, take, timeout } from 'rxjs/operators';
import { AuthenticatedUserDTO } from '../../models/authenticated-user.dto';
import { UserRole } from '../../models/user-role.enum';
import { CimServerService } from '../cim-server/cim-server.service';
import { UrlService } from '../url/url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser$: Observable<AuthenticatedUserDTO>;

  constructor(private urlService: UrlService, private cimServer: CimServerService) {
    const headers = {
      'caching-policy': 'NO_CACHE',
      'Access-Control-Allow-Origin': '*'
    };

    this.currentUser$ = this.cimServer.get<AuthenticatedUserDTO>('/rest/user/me',
      { headers }
    ).pipe(
      timeout(3000),
      catchError(() => of({ isImpersonated: false, role: UserRole.ANONYMOUS })),
      shareReplay(1)
    );
  }

  getCurrentUser(): Observable<AuthenticatedUserDTO> {
    return this.currentUser$;
  }

  getCurrentUserRole(): Observable<UserRole> {
    return this.getCurrentUser().pipe(
      take(1),  // to close right after call
      map(user => user.role),
      catchError(error => throwError(`Error while trying to get current user role: ${JSON.stringify(error)}`))
    );
  }

  getLoginUrl(previousUrl: string): string {
    return `${this.urlService.getUrl('login')}?previousUrl=${previousUrl}`;
  }
}
