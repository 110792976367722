import { domain as domainBR } from '../../../environments/domains/domain.br';
import { domain as domainCOM } from '../../../environments/domains/domain.com';
import { domain as domainDE } from '../../../environments/domains/domain.de';
import { domain as domainES } from '../../../environments/domains/domain.es';
import { domain as domainFR } from '../../../environments/domains/domain.fr';
import { domain as domainIT } from '../../../environments/domains/domain.it';
import { domain as domainUK } from '../../../environments/domains/domain.uk';

export enum Domain {
  FR = 'FR',
  ES = 'ES',
  IT = 'IT',
  DE = 'DE',
  UK = 'UK',
  COM = 'COM',
  BR = 'BR'
}

export const domains = {
  [Domain.BR]: domainBR,
  [Domain.COM]: domainCOM,
  [Domain.DE]: domainDE,
  [Domain.ES]: domainES,
  [Domain.FR]: domainFR,
  [Domain.IT]: domainIT,
  [Domain.UK]: domainUK
};
