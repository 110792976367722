import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ParseResultListed, ParseResultType, parseDomain } from 'parse-domain';
import { domain } from '../../../environments/domain';
import { SessionProvider } from '../../providers/session.provider';

@Injectable({ providedIn: 'root' })
export class RedirectHelperService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly sessionProvider: SessionProvider
  ) {
  }

  computeRedirectType(urlString: string): string | undefined {
    const url = this.getValidUrl(urlString);
    if (!url) {
      return undefined;
    }
    if (!this.isAppvizerDomain(url.hostname)) {
      return 'EXIT';
    }
    if (url?.pathname.split('/').slice(-1)[0] === domain.paths.hostedLandingPage) {
      return 'EXIT';
    }
    return 'BROWSE';
  }

  computeRedirectUrl(urlString: string): string {
    const url = this.getValidUrl(urlString);
    if (url && !this.isAppvizerDomain(url.hostname)) {
      const session = this.sessionProvider.getSession();
      return `${urlString}${urlString.includes('?') ? '&' : '?'}avuid=${session.uniqueId}&avsid=${session.sessionId}`;
    }
    return urlString;
  }

  /**
   *
   * @param hostname
   * @returns true if hostname main domain (i.e. 'toto' in 'www.toto.com.br') is 'appvizer' ('appvizer.dev' excluded for tests)
   */
  private isAppvizerDomain(hostname: string): boolean {
    const parseResult = parseDomain(hostname);
    if (parseResult.type === ParseResultType.Listed) {
      const result = (parseResult as ParseResultListed);
      return result.domain === 'appvizer' && !(result.topLevelDomains.length === 1 && result.topLevelDomains[0] === 'dev');
    }
    return false;
  }

  // If redirectUrl is not a valid url, try to construct a valide one
  // (exemple: redirectUrl = /test -> origin = http://localhost) try to construct http://localhost/test
  private getValidUrl(redirectUrl: string, origin?: string): URL | undefined {
    const url = `${origin ? origin : ''}${redirectUrl}`;
    try {
      return new URL(url);
    } catch (e) {
      // When new URL fails, try to construct a valid one with the origin to have a complete url
      // If the origin is defined, it means that a valid url can not be reconstructed.
      if (origin) {
        return undefined;
      }
      return this.getValidUrl(redirectUrl, this.document.location.origin);
    }

  }
}
