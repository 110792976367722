import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'cim-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];

  private memoizedRating = 0;
  private internalRating$ = new BehaviorSubject<number | null>(null);

  public stars: Array<string> = ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline'];
  public style?: any;

  @Input()
  set rating(value: number | undefined) {
    this.stars = this.getStars(value || 0);
  }

  @Input()
  set size(value: number) {
    const valuePx = `${value}px`;

    this.style = {
      fontSize: valuePx,
      height: valuePx,
      width: valuePx,
      minWidth: valuePx,
      lineHeight: valuePx
    };
  }

  @Input()
  edit = false;

  @Output()
  ratingChange = new EventEmitter<number>();

  ngOnInit(): void {
    this.subscriptions.push(
      this.internalRating$
        .pipe(debounceTime(100))
        .subscribe(internalRating => {
          if (!isNil(internalRating)) {
            this.rating = internalRating;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getStars(rating = 0): Array<string> {
    const stars = ['star-outline', 'star-outline', 'star-outline', 'star-outline', 'star-outline'];
    try {
      let index = 0;
      while (index < Math.floor(rating)) {
        stars[index] = 'star';
        index++;
      }

      if (Math.floor(rating) !== Math.ceil(rating)) {
        stars[index] = 'star-half-outlined';
      }
    }
    catch (err) {
      console.error(err);
    }
    return stars;
  }

  previewStars(index: number): void {
    if (this.edit) {
      this.internalRating$.next(index + 1);
    }
  }

  resetStars(): void {
    if (this.edit) {
      this.internalRating$.next(this.memoizedRating);
    }
  }

  setStars(index: number): void {
    if (this.edit) {
      this.memoizedRating = index + 1;
      this.rating = index + 1;
      this.ratingChange.next(index + 1);
    }
  }
}
