import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SessionProvider } from '../../../providers/session.provider';
import { RedirectHelperService } from '../../../widgets/redirect/redirect-helper.service';
import { ScriptLoaderService } from '../../../widgets/script-loader/script-loader.service';
import { AdDefinition, AdStatus } from '../../models/ad-definition/ad-definition.interface';
import { AppvizerGlobalTrackerService } from '../appvizer-tracker/appvizer-global-tracker.service';
import { AppvizerTrackerService } from '../appvizer-tracker/appvizer-tracker.service';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  private SCRIPT_ID = 'appvizer-promoted';

  /**
   * TEMPORARY 2024/04: disable the "ads disabling" mechanism for only one url
   * -> https://gitlab.appvizer.net/cim/a/-/issues/3959
   */
  private readonly disableAdsDisablingMechanism = this.document?.location?.pathname === '/magazine/ressources-humaines/recrutement/experience-candidat';

  constructor(
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly appvizerTrackerService: AppvizerTrackerService,
    private readonly sessionProvider: SessionProvider,
    private readonly appvizerGlobalTrackerService: AppvizerGlobalTrackerService,
    private readonly redirectHelperService: RedirectHelperService,
    private readonly platformService: PlatformService,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  /**
   * The selector that is mainly used to target native ads about softwares
   */
  public nativeAdSelector = '[data-software-id][data-software-has-cta=true]';

  /**
   * Add a CSS rule to disable all the pointer events on the native ads (detected via {@see this.nativeAdSelector}).
   *
   * When they'll be loaded in the ads script, they will be re-enabled one by one
   * (mechanism in {@see this.externalAdStatusChanged}) but just in case, re-enable them in a certain time (arbitrary)
   *
   * This whole mechanism is necessary since the native ads loading and their tracking are handled separatly.
   * One day, the native ads will be loaded directly by the ads-center script, and when that day will come,
   * all this won't be necessary anymore
   */
  public disableNativeAdsEvents(): void {
    if (this.disableAdsDisablingMechanism) {
      return;
    }

    const style: any = this.document.createElement('style');
    const css = `${this.nativeAdSelector} { pointer-events: none; }`;
    this.document.head.appendChild(style);
    if (style.styleSheet) { // this is required for IE8
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    setTimeout(() => {
      this.document.head.removeChild(style);
    }, 10000); // the ad will not really be unclickable for this amount of time since they are loaded dynamically by the browser
  }

  private internalAdStatusChanged(changes?: Array<{ status: AdStatus, elementRef?: HTMLElement, link: string }>): void {
    if (changes?.length) {
      changes.forEach(change => {
        if (change.elementRef) {
          switch (change.status) {
            case AdStatus.LOADED: {
              const redirectType = this.redirectHelperService.computeRedirectType(change.link);

              if (redirectType) {
                change.elementRef.setAttribute(`data-redirect-type`, redirectType);
              }
              change.elementRef.classList.remove('load');
              break;
            }
            case AdStatus.EMPTY:
            case AdStatus.ERROR:
              change.elementRef.parentElement?.remove();
              break;
          }
        }
      });
    }
  }

  /**
   * Default "status changed" for an ad with an external ID
   */
  private externalAdStatusChanged(
    changes?: Array<{
      status: AdStatus,
      elementRef?: HTMLElement,
      link: string
    }>): void {
    if (!changes?.length) {
      // no changes? weird but ok
      return;
    }

    changes.forEach(change => {
      if (!change?.elementRef) {
        return;
      }

      if (change.status === AdStatus.LOADED) {
        if (!this.disableAdsDisablingMechanism) {
          change.elementRef!.style.pointerEvents = 'auto';
        }

        this.appvizerGlobalTrackerService.send('ADVERT', { htmlElement: change.elementRef }).subscribe();
      }
    });
  }

  public load(ads: Array<AdDefinition>): Observable<any> {
    ads.forEach(adDefinition => {
      if (!adDefinition.type?.length && adDefinition.externalIdProperty?.length) {
        adDefinition.type = 'native_ad';
      }
    });
    const session = this.sessionProvider.getSession();
    return this.appvizerTrackerService.isTrackingDisabled()
      .pipe(
        tap((disableTracking) => {
          const appvizerDataLayer = (window as any).appvizerDataLayer = (window as any).appvizerDataLayer || [];
          appvizerDataLayer.push({
            type: 'load',
            params: {
              ads: ads.map(ad => {
                if (!ad.onStatusChanged) {
                  if (ad.externalIdProperty) {
                    ad.onStatusChanged = (...args) => this.externalAdStatusChanged(...args);
                  } else {
                    ad.onStatusChanged = (...args) => this.internalAdStatusChanged(...args);
                  }
                }
                return ad;
              }),
              uid: session.uniqueId,
              sid: session.sessionId,
              disableTracking
            }
          });
        })
      );
  }

  public enable(ads: Array<AdDefinition>): Observable<any> {
    this.loadScript();
    return this.load(ads);
  }

  /**
   * Load the appvizer promoted script using {@see PlatformService} in order to load it in SSR mode or in browser mode if it was not loaded in SSR
   */
  public loadScript(): void {
    this.platformService.proceedOnce(
      this.platformService.KEYS.APPVIZER_PROMOTED,
      () => this.scriptLoaderService
        .addScripts([
          {
            id: this.SCRIPT_ID,
            src: environment.adsCenterURL.script,
            async: true,
            defer: true
          }
        ]).subscribe()
    );
  }

  public refreshScroll(event?: Event): void {
    const appvizerDataLayer = (window as any).appvizerDataLayer = (window as any).appvizerDataLayer || [];
    appvizerDataLayer.push({
      type: 'refresh-scroll',
      params: {
        event
      }
    });
  }
}
