import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { DataAttribute } from '../../../widgets/data-attributes/data-attribute.model';
import { DataAttributesDirective } from '../../../widgets/data-attributes/data-attributes.directive';
import { DataAttributesService } from '../../../widgets/data-attributes/data-attributes.service';
import { RedirectionExtras } from '../../../widgets/redirect/redirect.model';
import { UIStateComponent } from '../../models/ui-state/ui-state.component';
import { SoftwareSectionTile } from './software-section-tile.model';

@Component({
  selector: 'cim-software-section-tile',
  templateUrl: './software-section-tile.component.html',
  styleUrls: ['./software-section-tile.component.scss']
})
export class SoftwareSectionTileComponent extends UIStateComponent {

  _software?: SoftwareSectionTile;
  tileLink!: string;
  tileNavigationParams?: RedirectionExtras;

  stateClass = 'success';
  imageAlt?: string;

  dataAttributes?: DataAttribute[];

  // RedirectSelectors define the areas that are commercial clicks
  @HostBinding('attr.data-redirect-selectors') redirectSelectors: string[] = [];
  @HostBinding('attr.cimDataAttributes') dataAttributesDirective = new DataAttributesDirective(this.elemRef);

  @Input()
  set software(software: SoftwareSectionTile | undefined) {
    if (software) {
      this._software = software;

      this.dataAttributes = this.dataAttributesService.generateSoftwareDataAttributes(software);
      this.dataAttributesDirective.dataAttributes = this.dataAttributes;
      if (software.cta && software.cta.url) {
        this.redirectSelectors.push('.software-section-tile');
        this.tileLink = software.cta.url;
        this.tileNavigationParams = software.cta.navigationParams;
      } else if (software.redirection && software.redirection.url) {
        this.tileLink = software.redirection.url;
        this.tileNavigationParams = software.redirection.navigationParams;
      }
    }
  }

  get software(): SoftwareSectionTile | undefined {
    return this._software;
  }

  constructor(private readonly dataAttributesService: DataAttributesService, private readonly elemRef: ElementRef) {
    super();
  }

  protected onLoad(): void {
    this.stateClass = 'load';
    this.imageAlt = 'placeholder';
  }

  protected onSuccess(): void {
    this.stateClass = 'success';
    this.imageAlt = this.software?.image?.alt;
  }
}
