import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { DataAttribute } from '../../../widgets/data-attributes/data-attribute.model';
import { DataAttributesDirective } from '../../../widgets/data-attributes/data-attributes.directive';
import { DataAttributesService } from '../../../widgets/data-attributes/data-attributes.service';
import { UIStateMetadata } from '../../models/ui-state/ui-state-metadata.model';
import { UIStateComponent } from '../../models/ui-state/ui-state.component';
import { UIStatus } from '../../models/ui-state/ui-status.enum';
import { SoftwareTile } from './software-tile.model';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class SoftwareTileComponent<U extends SoftwareTile, T extends UIStateMetadata> extends UIStateComponent<T> {

  protected _softwareTile?: U;

  public UIStatus = UIStatus;
  public stateClass = 'success';

  public logoAlt?: string;

  // RedirectSelectors define the areas that are commercial clicks
  @HostBinding('attr.data-redirect-selectors') redirectSelectors: string[] = [];

  public dataAttributes?: DataAttribute[];

  @HostBinding('attr.cimDataAttributes') dataAttributesDirective = new DataAttributesDirective(this.elemRef);

  @Input()
  @HostBinding('attr.data-position')
  public position?: string;

  constructor(protected readonly dataAttributesService: DataAttributesService, private readonly elemRef: ElementRef) {
    super();
  }

  protected setSoftwareTile(value: U | undefined): void {
    if (value) {
      this.dataAttributes = this.dataAttributesService.generateSoftwareDataAttributes(value);
      this.dataAttributesDirective.dataAttributes = this.dataAttributes;
    }
    this._softwareTile = value;
  }

  protected abstract get softwareTile(): U | undefined;

  protected onLoad(_metadata?: T): void {
    this.stateClass = 'load';
  }

  protected onSuccess(): void {
    this.stateClass = 'success';
    this.logoAlt = this._softwareTile?.logo?.alt;
  }
}
