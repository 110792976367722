import { AutocompleteResult } from '../../widgets/autocomplete/autocomplete-result.model';
import { BeforeRedirect } from '../../widgets/redirect/redirect.model';

export class SearchBarResult extends AutocompleteResult {
  url!: string;
  tag?: string;
  beforeRedirect?: BeforeRedirect;

  constructor(result: Partial<SearchBarResult>) {
    super();
    Object.assign(this, result);
  }
}
