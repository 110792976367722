import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@miles170/iconify-angular';
import { RatingComponent } from './rating.component';

@NgModule({
  declarations: [RatingComponent],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [RatingComponent]
})
export class RatingModule { }
