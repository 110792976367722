import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Position } from './position.model';

@Component({
  selector: 'cim-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  public labelTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);
  public contentTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  @ContentChild('labelTemplate', { static: true })
  set labelTemplate(value: TemplateRef<any>) {
    this.labelTemplateRef$.next(value);
  }

  @ContentChild('contentTemplate', { static: true })
  set contentTemplate(value: TemplateRef<any>) {
    this.contentTemplateRef$.next(value);
  }

  @Input()
  label!: string;

  @Input()
  redirectUrl?: string;

  @Input()
  toggle?: boolean;

  @Input()
  hasToggle?: boolean;

  @Input()
  options: Array<string> = [];

  @Input()
  position: Position = {
    top: 0,
    left: 0
  };

}
