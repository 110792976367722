<div class="rating-container">
  <cim-rating *ngIf="rating" class="rating-stars" [rating]="rating" [size]="large ? 26 : 15"></cim-rating>
  <span *ngIf="rating" class="cim-rating rating-value" [class.large]="large">{{_rating}}</span>
</div>
<div class="meta-review-label" [class.large]="large">
  <span class="primary default" [innerHTML]="label"></span>
  <div *ngIf="rating" class="reviews-info" [cimTooltip]="tooltipText" tooltipWidth="300px">
    <ic-icon class="reviews-info-icon" icon="info-circle-outline"></ic-icon>
  </div>
</div>
