import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AB_TEST_SESSION_STORAGE_DATA, AB_TEST_SESSION_STORAGE_NAME } from '../../ab-test.constants';
import { GlobalTrackingAdvertEvent } from '../../models/tracking/global/tracking-advert-event.model';
import { GlobalTrackingClickEvent } from '../../models/tracking/global/tracking-click-event.model';
import { GlobalTrackingEvent } from '../../models/tracking/global/tracking-event.model';
import { GlobalTrackingPageClosedEvent } from '../../models/tracking/global/tracking-page-closed-event.model';
import { GlobalTrackingPageOpenedEvent } from '../../models/tracking/global/tracking-page-opened-event.model';
import { MetaService } from '../meta/meta.service';
import { AppvizerTrackerService } from './appvizer-tracker.service';
import { DateProvider } from '../../../providers/date.provider';

@Injectable({ providedIn: 'root' })
export class AppvizerGlobalTrackerService {

  private visitId!: string;

  // store some input ids to ignore, because clicking on a label makes the browser trigger a second click on the input
  private ignoreInputIdClicks: { [inputId: string]: true } = {};

  private EXCLUDE_ANGULAR_ATTRIBUTES_PATTERN = /(^ng.*$)|(class)|(^_ng.*$)/;

  constructor(
    private appvizerTrackerService: AppvizerTrackerService,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) platformId: string,
    private readonly dateProvider: DateProvider
  ) {
    if (isPlatformBrowser(platformId)) {
      this.visitId = uuid();
    }
  }

  public send(eventType: string, input?: { event?: Partial<Event>; htmlElement?: HTMLElement }): Observable<Response | undefined> {
    const commonEvent: GlobalTrackingEvent = {
      type: eventType,
      visitId: this.visitId,
      width: window?.innerWidth,
      height: window?.innerHeight,
      url: window?.location.href,
      timestamp: this.dateProvider.currentDate(),
      ...this.metaService.getCustomMetaData()
    };

    if (sessionStorage) {
      commonEvent.abTestName = sessionStorage.getItem(AB_TEST_SESSION_STORAGE_NAME) || undefined;
      const abTestData = sessionStorage.getItem(AB_TEST_SESSION_STORAGE_DATA);
      if (abTestData) {
        commonEvent.abTestData = JSON.parse(abTestData);
      }
    }

    let typedEvent: GlobalTrackingEvent | undefined;

    switch (eventType) {
      case 'PAGE_OPENED':
        typedEvent = this.computePageOpenedEvent(input?.event, commonEvent);
        break;
      case 'CLICK':
        typedEvent = this.computeClickEvent((input?.event as MouseEvent), commonEvent);
        break;
      case 'PAGE_CLOSED':
        typedEvent = this.computePageClosedEvent(input?.event, commonEvent);
        break;
      case 'ADVERT':
        typedEvent = this.computeAdvertEvent(input?.htmlElement, commonEvent);
        break;
      default: break;
    }
    if (!typedEvent) {
      return of(undefined);
    }
    return this.appvizerTrackerService.sendFlat(typedEvent);
  }

  private computePageOpenedEvent(event?: Partial<Event>, globalTrackingEvent?: GlobalTrackingEvent): GlobalTrackingPageOpenedEvent {
    let partialPageOpenedEvent;
    if (event) {
      partialPageOpenedEvent = { loadDuration: event.timeStamp };
    }
    return { ...globalTrackingEvent, ...partialPageOpenedEvent };
  }

  private computeClickEvent(event?: MouseEvent, globalTrackingEvent?: GlobalTrackingEvent): GlobalTrackingClickEvent | undefined {
    let partialClickEvent;
    if (event) {
      // try to get the path that could be under some different things depending on the browser
      let eventPath = (event as any).path;
      if (!eventPath && event.composedPath) {
        if (typeof event.composedPath === 'function') {
          eventPath = event.composedPath();
        } else {
          eventPath = event.composedPath;
        }
      }

      // clicking on a label makes the browser also click the input,
      // so handle it by ignoring the input click if the label was clicked before
      if (eventPath.length) {
        const input = eventPath.find((item: any) => item.tagName === 'INPUT');

        if (input?.id && this.ignoreInputIdClicks[input.id]) {
          // the label with this for was already clicked, abort
          delete this.ignoreInputIdClicks[input.id];
          return;
        }

        const label = eventPath.find((item: any) => item?.getAttribute && item.tagName === 'LABEL');

        if (label) {
          const forAttr = label.getAttribute('for');
          if (forAttr && !this.ignoreInputIdClicks[forAttr]) {
            // register the id so we can ignore the input click if it is generated by the browser
            this.ignoreInputIdClicks[forAttr] = true;
          }
        }
      }

      partialClickEvent = {
        x: event.x,
        y: event.y,
        pageX: event.pageX,
        pageY: event.pageY,
        pageDuration: event.timeStamp,
        button: event.button,
        buttons: event.buttons,
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        altKey: event.altKey,
        path: (eventPath || []).map((item: any) => ({
          name: item.tagName,
          attributes: Array.from(item.attributes || [])
            .filter((attribute: any) => !this.EXCLUDE_ANGULAR_ATTRIBUTES_PATTERN.test(attribute.nodeName))
            .map((attribute: any) => ({
              name: attribute.nodeName,
              value: attribute.nodeValue
            }))
        }))
          .filter((item: any) => !!item.name)
      };
    }

    return { ...globalTrackingEvent, ...partialClickEvent };
  }

  private computeAdvertEvent(htmlElement?: HTMLElement, globalTrackingEvent?: GlobalTrackingEvent): GlobalTrackingAdvertEvent {
    let partialAdvertEvent: any;
    if (htmlElement) {
      const attributes = htmlElement.getAttributeNames().reduce((acc, name) => {
        if (this.EXCLUDE_ANGULAR_ATTRIBUTES_PATTERN.test(name)) {
          return acc;
        }
        return { ...acc, [name]: htmlElement.getAttribute(name) };
      }, {});

      partialAdvertEvent = {
        attributes,
        pageDuration: document.timeline?.currentTime
      };

      const position = htmlElement.getBoundingClientRect();
      if (position) {
        partialAdvertEvent.adWidth = position.width;
        partialAdvertEvent.adHeight = position.width;
        partialAdvertEvent.x = position.x;
        partialAdvertEvent.y = position.y;
      }
    }

    return { ...globalTrackingEvent, ...partialAdvertEvent };
  }

  private computePageClosedEvent(event?: Partial<Event>, globalTrackingEvent?: GlobalTrackingEvent): GlobalTrackingPageClosedEvent {
    let partialPageClosedEvent;
    if (event) {
      partialPageClosedEvent = { visitDuration: event.timeStamp };
    }
    return { ...globalTrackingEvent, ...partialPageClosedEvent };
  }
}
