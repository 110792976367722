import { Link } from '../shared/models/link.model';

export class Part {
  title = '';
  links: Array<LocalizedLink> = [];
}

export enum SocialNetwork {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  YOUTUBE = 'youtube'
}
export class LocalizedLink extends Link {
  translateKey!: string;
}

export class SocialLink extends Link {
  socialNetwork!: SocialNetwork;
  icon?: any;
}
