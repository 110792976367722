import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DateProvider } from '../../../providers/date.provider';
import { AlgoliaEventDTO } from '../../models/algolia-event.dto';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {
  private client: any;
  private indices: { [indexName: string]: any } = {};

  constructor(
    private http: HttpClient,
    private readonly dateProvider: DateProvider
  ) {
    this.client = algoliasearch(environment.algolia.applicationID, environment.algolia.apiKey);
  }

  private initIndex(indexName: string): any {
    return this.client.initIndex(indexName);
  }

  getIndex(indexName: string): any {
    if (!this.indices[indexName]) {
      this.indices[indexName] = this.initIndex(indexName);
    }
    return this.indices[indexName];
  }


  sendEvent(
    data: AlgoliaEventDTO,
  ): Observable<{ status: number, message: string }> {
    const body = {
      events:
        [{
          index: data.index,
          eventType: data.event,
          eventName: data.eventName,
          queryID: data.queryID,
          positions: data.positions,
          objectIDs: data.objectIDs,
          timestamp: this.dateProvider.now(),
          userToken: data.userToken
        }]
    };
    const headers = {
      'X-Algolia-API-Key': environment.algolia.apiKey,
      'X-Algolia-Application-Id': environment.algolia.applicationID,
      'Content-Type': 'application/json'
    };
    return this.http.post<{ status: number, message: string }>(`${environment.algolia.insights}/1/events`, body, { headers });
  }
}
