import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment } from '@angular/router';

const magazineMatcher: UrlMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
  const magazineTranslations = [
    //  FR, UK, COM
    'magazine',
    //  DE
    'magazin',
    //  ES, BR
    'revista',
    //  IT
    'rivista'
  ];

  const regexp = new RegExp(magazineTranslations.join('|'), 'gm');
  if (url.length > 0 && url[0].path.match(regexp)) {
    return {
      consumed: [new UrlSegment(url[0].path, url[0].parameters)]
    };
  }
  return null;
};

const serpMatcher: UrlMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
  const serpTranslations = [
    //  FR
    'recherche',
    //  UK, COM
    'search',
    //  DE
    'suche',
    //  ES
    'buscar',
    //  BR
    'pesquisar',
    //  IT
    'cerca'
  ];

  const regexp = new RegExp(serpTranslations.join('|'), 'gm');
  if (url.length > 0 && url[0].path.match(regexp)) {
    return {
      consumed: [new UrlSegment(url[0].path, url[0].parameters)]
    };
  }
  return null;
};

const paginatedMatcherBuilder = (posParamNames: string[]): UrlMatcher =>
  (url: UrlSegment[]): UrlMatchResult | null => {
    if (url.length === posParamNames.length + 1 && url[posParamNames.length].path.match(/^\d+$/)) {
      // we have a page number in here
      const posParams: any = {
        page: new UrlSegment(url[posParamNames.length].path, {})
      };

      posParamNames.forEach((posParamName, index) => posParams[posParamName] = new UrlSegment(url[index].path, {}));

      return { consumed: url, posParams };
    }

    return null;
  };

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(file => file.HomeModule)
  },
  {
    matcher: serpMatcher,
    loadChildren: () => import('./search-engine-result-page/search-engine-result-page.module')
      .then(file => file.SearchEngineResultPageModule)
  },
  {
    matcher: magazineMatcher,
    loadChildren: () => import('./magazine/magazine.module').then(file => file.MagazineModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then(file => file.NotFoundModule)
  },
  {
    path: ':gc_alias',
    loadChildren: () => import('./directory/directory.module').then(file => file.DirectoryModule),
    data: {
      trackedStateName: 'directory.gc'
    }
  },
  {
    matcher: paginatedMatcherBuilder(['gc_alias']),
    loadChildren: () => import('./directory/directory.module').then(file => file.DirectoryModule),
    data: {
      trackedStateName: 'directory.gc'
    }
  },
  {
    path: ':gc_alias/:sc_alias',
    loadChildren: () => import('./directory/directory.module').then(file => file.DirectoryModule),
    data: {
      trackedStateName: 'directory.sc'
    }
  },
  {
    matcher: paginatedMatcherBuilder(['gc_alias', 'sc_alias']),
    loadChildren: () => import('./directory/directory.module').then(file => file.DirectoryModule),
    data: {
      trackedStateName: 'directory.sc'
    }
  },
  {
    path: ':gc_alias/:sc_alias/:software_alias',
    loadChildren: () => import('./software/software.module').then(file => file.SoftwareModule)
  },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
