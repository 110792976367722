import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { domain } from '../../environments/domain';
import { NavbarStateService } from '../shared/services/navbar-state/navbar-state.service';
import { RouteHelperService } from '../shared/services/route-helper/route-helper.service';
import { UrlService } from '../shared/services/url/url.service';
import { RedirectService } from '../widgets/redirect/redirect.service';

@Component({
  selector: 'cim-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];

  public baseline?: string;

  public registerSoftware?: {
    url: string;
    label: string;
  };

  public hasSearchBar = true;
  public hasBoxShadow = true;
  public shouldFold = false;
  public isFolded = false;

  constructor(
    private urlService: UrlService,
    private translate: TranslateService,
    private redirect: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService,
    private navbarStateService: NavbarStateService
  ) {

  }

  ngOnInit(): void {
    //  Checks if search bar is required on current url
    this.subscriptions.push(this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(_ => this.routeHelper.getFromMostNestedRouteData<boolean>(this.route, 'hasSearchBar'))
      )
      .subscribe(hasSearchBar => {
        this.hasSearchBar = isNil(hasSearchBar) || hasSearchBar;
      })
    );

    this.translate.get(['REGISTER', 'BASELINE'])
      .subscribe(translations => {
        this.baseline = translations.BASELINE;
        this.registerSoftware = {
          label: translations.REGISTER,
          url: this.urlService.getUrl('selfRegistration')
        };
      });

    this.navbarStateService.getBoxShadow()
      .subscribe(hasBoxShadow => {
        this.hasBoxShadow = hasBoxShadow;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public search(): void {
    this.redirect.navigate(`/${domain.paths.search}`);
  }
}
