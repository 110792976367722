import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { NavigationMenu } from '../navigation-menu.model';
import { NavigationMenuService } from '../navigation-menu.service';

@Component({
  selector: 'cim-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  public mobileTopTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  @ContentChild('mobileTopTemplate', { static: true })
  set mobileTopTemplate(value: TemplateRef<any>) {
    this.mobileTopTemplateRef$.next(value);
  }

  public menu?: NavigationMenu;
  public nonHighlightedCategoriesLabel?: string;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    forkJoin([this.translate.get('NAVIGATION.ACTIVITY_AREA'), this.navigationMenuService.getNavigationMenu()])
      .subscribe(([translation, menu]) => {
        if (!menu?.highlightedCategories?.length) {
          return;
        }
        this.nonHighlightedCategoriesLabel = translation;
        this.menu = menu;
      });
  }


}
