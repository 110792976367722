<header class="navbar" [ngClass]="{'box-shadow': hasBoxShadow, 'folded': isFolded}">
  <div class="nav-container">
    <div class="nav1">
      <div class="col-1">
        <cim-search-bar *ngIf="hasSearchBar"></cim-search-bar>
        <ic-icon icon="magnify" class="search-logo" (click)="search()"></ic-icon>
        <div class="logo-baseline">
          <a cimRedirect="/">
            <img loading="lazy" src="assets/images/logo-Appvizer-marine.webp" alt="appvizer logo">
          </a>
          <p class="d-baseline cim-caption">
            {{baseline}}
          </p>
        </div>
      </div>
      <div class="col-2">
        <cim-account></cim-account>
        <a class="register-software button default steel"
          [cimRedirect]="registerSoftware?.url">{{registerSoftware?.label}}</a>
      </div>
    </div>
    <nav class="nav2">
      <cim-navigation-bar>
      </cim-navigation-bar>
    </nav>
  </div>
  <p class="m-baseline cim-caption">
    {{baseline}}
  </p>
</header>
