import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PlatformService {

  public readonly KEYS = {
    APPVIZER_PROMOTED: 'APPVIZER_PROMOTED'
  };

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  /**
   * If we have a SSR, run the {@param action} only on SSR and not in browser (using transferState).
   * If not, just run the {@param action}.
   *
   * Some actions are run only in SSR mode like setting the meta of a page. This method is usefull
   * for running these actions even if there's no SSR at all
   *
   * @param key used to identify the action in the transferState
   */
  proceedOnce(key: string, action: () => any): void {
    const stateKey = makeStateKey<boolean>(key);
    if (!this.transferState.get(stateKey, null)) {
      action();

      if (isPlatformServer(this.platformId)) {
        this.transferState.set(stateKey, true);
      }
    }
  }
}
