import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMapTo } from 'rxjs/operators';
import { UserRole } from '../shared/models/user-role.enum';
import { AuthService } from '../shared/services/auth/auth.service';
import { AdminButtonModel } from './admin-button.model';

@Injectable({ providedIn: 'root' })
export class AdminButtonsService {

  public isDisplayed$ = new BehaviorSubject<boolean>(false);
  public buttons$ = new BehaviorSubject<Array<AdminButtonModel>>([]);

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.isDisplayed$.subscribe(isDisplayed => {
      if (!isDisplayed) {
        //  Delete current entity if removed from screen
        this.buttons$.next([]);
      }
    });
  }

  /**
   * Listen for NavigationEnds to check whether the admin buttons can be displayed or not
   */
  public toggleAdministrationPanelOnNavigationEnd(): Observable<boolean> {
    return this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        filter(_ => isPlatformBrowser(this.platformId)),
        switchMapTo(this.authService.getCurrentUser()),
        map(user => {
          const isAdmin = user.role === UserRole.ADMIN || user.isImpersonated;
          this.isDisplayed$.next(isAdmin);
          return isAdmin;
        })
      );
  }
}
