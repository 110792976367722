import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { domain } from '../../../../environments/domain';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http: HttpClient) { }

  submit(email: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'text/plain');
    const user = { email, 'user-type': `User ${domain.id} (marketing)` };
    return this.http.post(environment.zapierWebhook, JSON.stringify(user), { headers });
  }
}
