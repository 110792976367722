import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { DataAttributesModule } from '../../../../widgets/data-attributes/data-attributes.module';
import { MetaReviewModule } from '../../../../widgets/meta-review/meta-review.module';
import { RatingModule } from '../../../../widgets/rating/rating.module';
import { RedirectModule } from '../../../../widgets/redirect/redirect.module';
import { IntegralSoftwareTileComponent } from './integral-software-tile.component';

@NgModule({
  declarations: [
    IntegralSoftwareTileComponent
  ],
  imports: [
    CommonModule,
    RatingModule,
    RedirectModule,
    IconModule,
    DataAttributesModule,
    MetaReviewModule
  ],
  exports: [
    IntegralSoftwareTileComponent
  ]
})
export class IntegralSoftwareTileModule {
}
