import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DateProvider } from './date.provider';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SessionProvider {

  private session = { uniqueId: "", sessionId: "" };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly dateProvider: DateProvider,
    private readonly cookieService: CookieService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    if (isPlatformBrowser(platformId)) {
      const generated = this.dateProvider.now().toString();

      this.session = {
        uniqueId: this.cookieService.get('uniqueId') || generated,
        sessionId: this.cookieService.get('sessionId') || generated
      };

      this.cookieService.set('uniqueId', this.session.uniqueId, 365, undefined, undefined, true, 'None');
      this.cookieService.set('sessionId', this.session.sessionId, 30, undefined, undefined, true, 'None');

      this.httpClient.get<{ uniqueId: string, sessionId: string }>(
        `${environment.trackingAppvizerURL}/ariadne/session`, // Copy session to tracking domain for external tracking if third party cookies are enabled
        {
          withCredentials: true, // Mandatory to send cookies
          params: {
            avuid: this.session.uniqueId,
            avsid: this.session.sessionId,
          }
        }
      ).subscribe();
    }
  }

  getSession(): { uniqueId: string, sessionId: string } {
    return this.session;
  }
}
