import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NewsletterModule } from '../shared/modules/newsletter/newsletter.module';
import { RedirectModule } from '../widgets/redirect/redirect.module';
import { SelectModule } from '../widgets/select/select.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NewsletterModule,
    SelectModule,
    RedirectModule
  ],
  exports: [FooterComponent]
})
export class FooterModule {
}
