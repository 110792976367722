import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { ArticleTileModule } from '../shared/modules/article-tile/article-tile.module';
import { HeadlineTileModule } from '../shared/modules/headline-tile/headline-tile.module';
import { IntegralSoftwareTileModule } from '../shared/modules/software-tile/integral-software-tile/integral-software-tile.module';
import { RedirectModule } from '../widgets/redirect/redirect.module';
import { AccountComponent } from './account/account.component';
import { HeaderComponent } from './header.component';
import { NavigationBarModule } from './navigation-bar/navigation-bar.module';
import { SearchBarModule } from './search-bar/search-bar.module';

@NgModule({
  declarations: [HeaderComponent, AccountComponent],
  imports: [
    CommonModule,
    IconModule,
    HeadlineTileModule,
    ArticleTileModule,
    IntegralSoftwareTileModule,
    NavigationBarModule,
    RedirectModule,
    SearchBarModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
