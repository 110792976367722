import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { RatingModule } from '../rating/rating.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { MetaReviewComponent } from './meta-review.component';

@NgModule({
  declarations: [MetaReviewComponent],
  imports: [
    CommonModule,
    IconModule,
    RatingModule,
    TooltipModule
  ],
  exports: [MetaReviewComponent]
})
export class MetaReviewModule { }
