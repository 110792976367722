import { Injectable } from '@angular/core';
import { RedirectionExtras } from './redirect.model';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  private SELF_TARGET = '_self';

  /** Redirects to the specified external link */
  public redirect(url: string, target: string = this.SELF_TARGET, targetWindow?: Window | null): void {
    if (targetWindow) {
      targetWindow.location.href = url;
    } else {
      window.open(url, target);
    }
  }

  /** Navigates to the given url, redirecting when necessary
   * @param url An absolute URL. The function does not apply any delta to the current URL.
   * When starting with 'http(s)://' triggers the external redirection.
   * @param extras (optional). An object containing properties that modify the navigation strategy.
   * The function ignores any properties that would change the provided URL.
   */
  public navigate(url: string, extras?: RedirectionExtras, targetWindow?: Window | null): void {
    this.redirect(url, extras?.target, targetWindow);
  }
}
