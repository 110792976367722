import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogContent } from './dialog-content.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private _content = new BehaviorSubject<DialogContent | undefined>(undefined);

  get content(): BehaviorSubject<DialogContent | undefined> {
    return this._content;
  }

  open<ComponentType>(content: DialogContent<ComponentType>): void {
    this._content.next(content);
  }

  close(): void {
    this._content.next(undefined);
  }
}
