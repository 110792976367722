import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, defer, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { domain } from '../../../../environments/domain';
import { environment } from '../../../../environments/environment';
import { GlobalTrackingEvent } from '../../models/tracking/global/tracking-event.model';
import { UserRole } from '../../models/user-role.enum';
import { AuthService } from '../auth/auth.service';
import { RouteHelperService } from '../route-helper/route-helper.service';

@Injectable({ providedIn: 'root' })
export class AppvizerTrackerService {

  private isTrackingDisabled$: Observable<boolean>;
  protected CTA_HISTORY_LOCAL_STORAGE_NAME = 'CTA_HISTORY';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly routeHelper: RouteHelperService,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
    this.isTrackingDisabled$ = this.auth.getCurrentUser().pipe(
      map(user => user.role === UserRole.ADMIN || user.isImpersonated),
      map(isAdmin => isAdmin || this.isPreviewMode()),
      switchMap(isTrackingDisabled => {
        if (!isTrackingDisabled) {
          return this.httpClient.get<boolean>(environment.serverUrl[domain.id] + '/ariadne/is-filtered', { withCredentials: true });
        } else {
          return of(true);
        }
      }),
      catchError(() => of(false)), // it's sad, but we'll but consider that tracking is not disabled
      shareReplay(1)
    );
  }

  isTrackingDisabled(): Observable<boolean> {
    return this.isTrackingDisabled$;
  }

  isPreviewMode(): boolean {
    const previewParamValue = this.route.snapshot.queryParamMap.get('preview');
    return previewParamValue === true.toString() || (!!previewParamValue && /^\d+$/.test(previewParamValue));
  }

  private getVanillaCustomHeaders(): Headers {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (isPlatformBrowser(this.platformId)) {
      if (this.document.referrer) {
        headers.append('Referer-Custom', this.document.referrer);
      }
    }
    return headers;
  }

  sendFlat<T extends GlobalTrackingEvent>(trackedEvent: T): Observable<Response> {
    const stateName = this.routeHelper.getFromMostNestedRouteData<string>(this.route, 'trackedStateName');
    trackedEvent = { ...trackedEvent, stateName };

    return this.isTrackingDisabled().pipe(
      filter(isTrackingDisabled => !isTrackingDisabled),
      switchMap(() => {
        return defer(() => fetch(`${environment.serverUrl[domain.id]}/ariadne/flat`, {
          method: 'POST',
          headers: this.getVanillaCustomHeaders(),
          keepalive: true,
          body: JSON.stringify(trackedEvent),
          credentials: 'include'
        }));
      }));
  }
}
