import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cim-meta-review',
  templateUrl: './meta-review.component.html',
  styleUrls: ['./meta-review.component.scss']
})
export class MetaReviewComponent {
  @Input()
  label?: string;

  @Input()
  tooltipText?: string;

  @Input()
  rating?: number;

  get _rating(): string {
    return this.rating?.toFixed(1) || '';
  }

  @Input()
  @HostBinding('class.large')
  large = false;
}
