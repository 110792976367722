import localeDe from '@angular/common/locales/de';

export const domain = {
  id: 'DE',
  locale: 'de_DE',
  isoLocale: 'de-DE',
  siteName: 'appvizer.de',
  ngLocale: localeDe as any,
  algoliaIndexSuffix: 'DE',
  externalUrls: {
    support: 'https://help.appvizer.com/en',
    facebook: 'https://www.facebook.com/appvizer.de',
    linkedin: 'https://www.linkedin.com/company/appvizer-de',
    twitter: 'https://twitter.com/appvizer_DE',
    youtube: 'https://www.youtube.com/c/Appvizer',
  },
  paths: {
    about: 'uber-uns',
    privacyPolicy: 'uber-uns/datenschutz',
    termsOfUse: 'uber-uns/nutzungsbedingungen',
    transparency: 'uber-uns/transparenz',
    search: 'suche',
    magazine: 'magazin',
    compare: 'vergleich',
    softwareReviews: 'bewertungen',
    softwareAlternatives: 'alternativen',
    hostedLandingPage: 'registrierung',
    categories: 'kategorien',
    author: 'autor',
    news: 'aktuelles',
    sitemaps: 'sitemaps',
    magazineSitemap: 'sitemaps/magazin',
    selfRegistration: 'partner/registrierung',
    login: 'anmelden',
    account: 'account/profil',
    editorServices: 'editor/services',
    editorStatistics: 'editor/statistics',
    editorOffers: 'editor/offers',
    editorLeads: 'editor/leads',
    boProspects: 'bo/prospects',
    boVendors: 'bo/vendors',
    boSalesProposal: 'bo/sales-proposal/manage',
    boUsers: '-bo/users',
    boServices: 'bo/services',
    boItems: '-bo/items',
    boContent: 'bo/content',
    boSoftwareGroups: '-bo/software-groups',
    boTests: '-bo/tests'
  }
};
