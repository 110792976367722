import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@miles170/iconify-angular';
import { TranslateModule } from '@ngx-translate/core';
import { SearchBarComponent } from './search-bar.component';
import { AutocompleteModule } from '../../widgets/autocomplete/autocomplete.module';
import { RedirectModule } from '../../widgets/redirect/redirect.module';

@NgModule({
  declarations: [
    SearchBarComponent
  ],
  imports: [
    CommonModule,
    AutocompleteModule,
    IconModule,
    RedirectModule,
    TranslateModule.forChild()
  ],
  exports: [
    SearchBarComponent
  ]
})
export class SearchBarModule {
}

