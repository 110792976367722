import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataAttribute } from '../data-attributes/data-attribute.model';
import { DropdownContext } from './dropdown-context.model';

@Component({
  selector: 'cim-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  public context: DropdownContext = new DropdownContext();
  public labelTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);
  public contentTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);

  @ContentChild('labelTemplate', { static: true })
  set labelTemplate(value: TemplateRef<any>) {
    this.labelTemplateRef$.next(value);
  }

  @ContentChild('contentTemplate', { static: true })
  set contentTemplate(value: TemplateRef<any>) {
    this.contentTemplateRef$.next(value);
  }

  @Input()
  dropdownId!: string | number;

  @Input()
  label!: string;

  @Input()
  redirectUrl?: string;


  @Input()
  dataAttributes?: DataAttribute[];

  @Input()
  hasToggle?: boolean;

  @Input()
  options: Array<string> = [];
}
