import { Directive, Input } from '@angular/core';
import { UIStateMetadata } from './ui-state-metadata.model';
import { UIState } from './ui-state.model';
import { UIStatus } from './ui-status.enum';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class UIStateComponent<T extends UIStateMetadata = UIStateMetadata> {
  private _state: UIState<T> = new UIState(UIStatus.SUCCESS);

  @Input()
  set state(value: UIState<T>) {
    this._state = value;

    switch (value.status) {
      case UIStatus.EMPTY:
        this.onEmpty(value.metadata);
        break;
      case UIStatus.LOAD:
        this.onLoad(value.metadata);
        break;
      case UIStatus.SUCCESS:
        this.onSuccess(value.metadata);
        break;
      case UIStatus.ERROR:
        this.onError(value.metadata);
        break;
    }
  }

  get state(): UIState<T> {
    return this._state;
  }

  protected onEmpty(_metadata?: T): void {
    return;
  }

  protected onLoad(_metadata?: T): void {
    return;
  }

  protected onSuccess(_metadata?: T): void {
    return;
  }

  protected onError(_metadata?: T): void {
    return;
  }
}
