import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { RedirectModule } from '../redirect/redirect.module';
import { SelectComponent } from './select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [
    CommonModule,
    IconModule,
    RedirectModule
  ],
  exports: [SelectComponent]
})
export class SelectModule { }
