import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, StateKey, TransferState, makeStateKey } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { domain } from '../../environments/domain';
import { environment } from '../../environments/environment';
import { CategoryDTO } from '../shared/models/public-gateway/category.dto';
import { GraphQLService } from '../shared/services/graphql/graphql.service';
import { NavigationItem } from './navigation-item.model';
import { NavigationMenu } from './navigation-menu.model';


@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {
  public NAVIGATION_MENU_STATE_KEY: StateKey<NavigationMenu> = makeStateKey<NavigationMenu>('NAVIGATION_MENU_STATE');

  constructor(
    private graphQLService: GraphQLService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  private convertToNavigationItem(
    categoryDTO: CategoryDTO
  ): NavigationItem {
    return {
      name: categoryDTO.categoryDomain?.magazineCategory?.shortTitle || categoryDTO.categoryDomain?.name,
      magazinePath: categoryDTO.categoryDomain?.magazineCategory ?
        `${environment.domains[domain.id].urls.home}/${domain.paths.magazine}${categoryDTO.categoryDomain.path}` :
        undefined
    };
  }

  public getNavigationMenu(): Observable<NavigationMenu> {
    const transferStateMenu = this.transferState.get<NavigationMenu>(this.NAVIGATION_MENU_STATE_KEY, { notHighlightedCategories: [], highlightedCategories: [] });
    if (transferStateMenu.notHighlightedCategories.length > 0 || transferStateMenu.highlightedCategories.length > 0) {
      return of(transferStateMenu);
    }

    const query = `
        query GetNavigationMenu($domain: Domain!) {
          highlightedCategories: categories(
            level: 1
            isHighlighted: true
            hasPublishedArticlesInDomain: $domain
            sorts: [{ order: DESC, property: highlightedPriority }]
          ) {
            categoryDomain(domain: $domain) {
              name
              path
              magazineCategory {
                shortTitle
              }
            }
          }

          notHighlightedCategories: categories(
            level: 1
            isHighlighted: false
            hasPublishedArticlesInDomain: $domain
            sorts: [{ order: DESC, property: nbPublishedArticlesInDomain${domain.id} }]
          ) {
            id
            categoryDomain(domain: $domain) {
              name
              path
              magazineCategory {
                shortTitle
              }
            }
          }
        }`;
    return this.graphQLService.query<{
      highlightedCategories: Array<CategoryDTO>,
      notHighlightedCategories: Array<CategoryDTO>
    }>(
      'public-gateway',
      query,
      { domain: domain.id }
    ).pipe(
      map(result => result.data),
      map(data => {
        const menu = new NavigationMenu();
        menu.highlightedCategories = (data.highlightedCategories || []).map(category => this.convertToNavigationItem(category));
        menu.notHighlightedCategories = (data.notHighlightedCategories || []).map(category => this.convertToNavigationItem(category));
        return menu;
      }),
      tap(navigationMenu => {
        if (isPlatformServer(this.platformId)) {
          //  Clone navigation menu to avoid further modification by reference
          this.transferState.set(this.NAVIGATION_MENU_STATE_KEY, JSON.parse(JSON.stringify(navigationMenu)));
        }
      }),
      catchError(err => {
        console.error(JSON.stringify(err));
        return throwError(() => err);
      })
    );
  }
}
