import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  public static readonly clients = [
    'ads-center',
    'reviews-center',
    'public-gateway',
  ] as const;

  preview = false;
  nocache = false;

  constructor(
    private apollo: Apollo,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REQUEST) private httpRequest: any
  ) {}

  /**
   * Listen for router events to handle the queries modifiers
   */
  public handleRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event) => event as RoutesRecognized),
        tap((event) => {
          const queryParams = event.state.root.queryParams;
          this.preview = queryParams.preview === true.toString();
          this.nocache = queryParams.nocache === true.toString();
        })
      )
      .subscribe();
  }

  /**
   * Queries through GraphQL
   * @param client Apollo client to call
   * @param query Get query
   * @param variables Variables passed to query
   * @returns Queries object
   */
  public query<T>(
    client: (typeof GraphQLService.clients)[number],
    query: string,
    variables?: { [name: string]: any }
  ): Observable<ApolloQueryResult<T>> {
    const nocacheHeader = this.httpRequest
      ? this.httpRequest.get('X-No-Cache')
      : '';
    const nocache = this.nocache || nocacheHeader === true.toString();

    const headers: { [name: string]: string } = {
      'X-Preview': this.preview.toString(),
      'X-No-Cache': nocache.toString(),
    };

    if (this.httpRequest) {
      // If there is a forwarded-for header, we transmit it preferring the appvizer one as the x can be overided by a bad proxy configuration
      const ip = this.httpRequest.headers['appvizer-forwarded-for'] || this.httpRequest.headers['x-forwarded-for'] || this.httpRequest.socket?.remoteAddress;
      if (ip) {
        headers['x-forwarded-for'] = ip;
        headers['appvizer-forwarded-for'] = ip;
      }
    }

    return this.apollo.use(this.mapApolloClientName(client)).query<T>({
      query: gql(query),
      variables,
      context: { headers },
    });
  }

  /**
   * Mutates through GraphQL
   * @param client Apollo client to call
   * @param mutation Mutation query
   * @param variables Variables passed to query
   * @returns A mutated result notification
   */
  public mutate<T>(
    client: (typeof GraphQLService.clients)[number],
    mutation: string,
    variables?: { [name: string]: any }
  ): Observable<FetchResult<T, Record<string, any>, Record<string, any>>> {
    return this.apollo.use(this.mapApolloClientName(client)).mutate<T>({
      mutation: gql(mutation),
      variables,
    });
  }

  private mapApolloClientName(
    client: (typeof GraphQLService.clients)[number]
  ): string {
    if (client === 'public-gateway' && isPlatformServer(this.platformId)) {
      return `${client}-private`;
    } else {
      return client;
    }
  }
}
