import { Inject, Injectable, Optional } from '@angular/core';
import MessageFormat, { MessageFormatOptions } from '@messageformat/core';
import { TranslateCompiler } from '@ngx-translate/core';
import {
  defaultConfig,
  MessageFormatConfig,
  MESSAGE_FORMAT_CONFIG
} from './message-format-config';

/**
 * This compiler comes from the github repo: https://github.com/lephyrus/ngx-translate-messageformat-compiler
 * At this moment, ngx-translate-messageformat-compiler does not work on the project because it uses a deprecated version of messageformat.
 * A PR is opened on the repository since 3 month to fix it but there is not update.
 * To be replaced by the lib when the new version is published
 */

/**
 * This compiler expects ICU syntax and compiles the expressions with messageformat.js
 */
@Injectable()
export class TranslateMessageFormatCompiler extends TranslateCompiler {
  private mfCache = new Map<string, MessageFormat>();
  private config: MessageFormatOptions<'string'>;

  constructor(
    @Optional()
    @Inject(MESSAGE_FORMAT_CONFIG)
    config?: MessageFormatConfig
  ) {
    super();

    const {
      formatters: customFormatters,
      biDiSupport,
      strictNumberSign: strict,
    } = {
      ...defaultConfig,
      ...config,
    };

    this.config = { customFormatters, biDiSupport, strict };
  }

  public compile(value: string, lang: string): (params: any) => string {
    return this.getMessageFormatInstance(lang).compile(value);
  }

  public compileTranslations(translations: any, lang: string): any {
    if (typeof translations === 'string') {
      return this.compile(translations, lang);
    }
    return Object.keys(translations).reduce<{ [key: string]: any }>(
      (acc, key) => {
        const value = translations[key];
        return { ...acc, [key]: this.compileTranslations(value, lang) };
      },
      {}
    );
  }

  private getMessageFormatInstance(locale: string): MessageFormat {
    let messageFormat = this.mfCache.get(locale);
    if (!messageFormat) {
      messageFormat = new MessageFormat<'string'>(locale, this.config);
      this.mfCache.set(
        locale,
        new MessageFormat<'string'>(locale, this.config)
      );
    }

    return messageFormat;
  }
}
