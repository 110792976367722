<div class="headline-tile" [ngClass]="stateClass" [cimRedirect]="url" [cimDataAttributes]="dataAttributes">
  <div class="image-container">
    <img loading="lazy" *ngIf="backgroundPicture" [src]="backgroundPicture" alt="{{tagLabel}} background">
    <img loading="lazy" *ngIf="coverPicture && coverPicture.url" [src]="coverPicture.url" [alt]="coverPicture.alt">
  </div>
  <div class="headline-tile-caption">
    <div class="cim-caption">{{tagLabel}}</div>
    <div class="clamp">
      <a [class]="titleClass" [cimRedirect]="url">{{title}}</a><br>
      <span class="cim-caption summary">{{summary}}</span>
    </div>
  </div>
</div>