<ng-container *ngIf="buttons$ | async as buttons">
  <div class="admin-buttons" *ngIf="buttons.length">
    <ng-container *ngFor="let button of buttons">
      <button (click)="handleClick(button)" [disabled]="button.disabled" class="button secondary"
        [title]="button?.error?.message ?? ''">
        <span *ngIf="!button.loading">{{button.title}}</span>
        <ic-icon *ngIf="button.loading" icon="loading" class="spinner"></ic-icon>
      </button>
    </ng-container>
  </div>
</ng-container>