import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Link } from '../../shared/models/link.model';
import { UserRole } from '../../shared/models/user-role.enum';
import { AuthService } from '../../shared/services/auth/auth.service';
import { UrlService } from '../../shared/services/url/url.service';
import { AccountMenuItem } from './account-menu-item.model';

@Component({
  selector: 'cim-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public menuActionLabel!: string;
  public login?: Link;
  public userRole$ = new BehaviorSubject<UserRole>(UserRole.ANONYMOUS);
  public items: AccountMenuItem[] = [];

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private urlService: UrlService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  ngOnInit(): void {
    this.translate.get([
      'HEADER.ACCOUNT.LOGIN',
      'HEADER.ACCOUNT.MENU.MY_ACCOUNT',
      'HEADER.ACCOUNT.MENU.LOGOUT'
    ]).subscribe(translations => {
      this.login = {
        url: this.urlService.getUrl('login'),
      };

      this.userRole$
        .pipe(
          switchMap(userRole => {
            if (userRole === UserRole.ANONYMOUS) {
              this.menuActionLabel = translations['HEADER.ACCOUNT.LOGIN'];
              return of(null);
            } else {
              this.menuActionLabel = translations['HEADER.ACCOUNT.MENU.MY_ACCOUNT'];
              return this.getMenu(userRole);
            }
          }),
          map(items => {
            if (items) {
              return [
                ...items,
                {
                  name: translations['HEADER.ACCOUNT.MENU.LOGOUT'],
                  url: this.urlService.getUrl('login')
                }
              ];
            } else {
              return [];
            }
          })
        )
        .subscribe(items => {
          this.items = items;
        });
    });

    if (isPlatformBrowser(this.platformId)) {
      this.authService.getCurrentUserRole().subscribe(type => this.userRole$.next(type));
    }
  }

  getMenu(userRole: UserRole): Observable<AccountMenuItem[]> {
    switch (userRole) {
      case UserRole.USER: {
        return this.translate.get('HEADER.ACCOUNT.MENU.MY_ACCOUNT')
          .pipe(map(name => [{
            name,
            url: this.urlService.getUrl('account'),
          }]));
      }
      case UserRole.EDITOR: {
        return this.translate.get(
          [
            'HEADER.ACCOUNT.MENU.MY_ACCOUNT',
            'HEADER.ACCOUNT.MENU.MY_SOFTWARE',
            'HEADER.ACCOUNT.MENU.MY_ANALYSIS',
            'HEADER.ACCOUNT.MENU.MY_PLANS',
            'HEADER.ACCOUNT.MENU.MY_LEADS'
          ]
        )
          .pipe(
            map(labels => [
              {
                name: labels['HEADER.ACCOUNT.MENU.MY_ACCOUNT'],
                url: this.urlService.getUrl('account'),
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.MY_SOFTWARE'],
                url: this.urlService.getUrl('editorServices'),
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.MY_ANALYSIS'],
                url: this.urlService.getUrl('editorStatistics'),
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.MY_PLANS'],
                url: this.urlService.getUrl('editorOffers'),
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.MY_LEADS'],
                url: this.urlService.getUrl('editorLeads'),
              }
            ])
          );
      }
      case UserRole.ADMIN: {
        return this.translate.get([
          'HEADER.ACCOUNT.MENU.MY_ACCOUNT',
          'HEADER.ACCOUNT.MENU.CRM',
          'HEADER.ACCOUNT.MENU.VENDORS',
          'HEADER.ACCOUNT.MENU.PLANS',
          'HEADER.ACCOUNT.MENU.USERS',
          'HEADER.ACCOUNT.MENU.SOFTWARE',
          'HEADER.ACCOUNT.MENU.FEATURES',
          'HEADER.ACCOUNT.MENU.CONTENT',
          'HEADER.ACCOUNT.MENU.SOFTWARE_GROUP',
          'HEADER.ACCOUNT.MENU.TESTS',
        ])
          .pipe(
            map(labels => [
              {
                name: labels['HEADER.ACCOUNT.MENU.MY_ACCOUNT'],
                url: this.urlService.getUrl('account')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.CRM'],
                url: this.urlService.getUrl('boProspects')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.VENDORS'],
                url: this.urlService.getUrl('boVendors')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.PLANS'],
                url: this.urlService.getUrl('boSalesProposal')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.USERS'],
                url: this.urlService.getUrl('boUsers')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.SOFTWARE'],
                url: this.urlService.getUrl('boServices')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.FEATURES'],
                url: this.urlService.getUrl('boItems')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.CONTENT'],
                url: this.urlService.getUrl('boContent')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.SOFTWARE_GROUP'],
                url: this.urlService.getUrl('boSoftwareGroups')
              },
              {
                name: labels['HEADER.ACCOUNT.MENU.TESTS'],
                url: this.urlService.getUrl('boTests')
              }
            ])
          );
      }
      default: {
        return of([]);
      }
    }
  }

}
