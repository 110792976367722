import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { DataAttributesModule } from '../data-attributes/data-attributes.module';
import { RedirectModule } from '../redirect/redirect.module';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [
    CommonModule,
    IconModule,
    RedirectModule,
    DataAttributesModule
  ],
  exports: [DropdownComponent]
})
export class DropdownModule { }
