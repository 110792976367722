import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@miles170/iconify-angular';
import { HeadlineTileModule } from '../../shared/modules/headline-tile/headline-tile.module';
import { SoftwareSectionTileModule } from '../../shared/modules/software-section-tile/software-section-tile.module';
import { IntegralSoftwareTileModule } from '../../shared/modules/software-tile/integral-software-tile/integral-software-tile.module';
import { DropdownModule } from '../../widgets/dropdown/dropdown.module';
import { RedirectModule } from '../../widgets/redirect/redirect.module';
import { NavigationBarComponent } from './navigation-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    NavigationBarComponent
  ],
  imports: [
    CommonModule,
    RedirectModule,
    IconModule,
    SoftwareSectionTileModule,
    HeadlineTileModule,
    IntegralSoftwareTileModule,
    DropdownModule,
    TranslateModule.forChild(),
  ],
  exports: [
    NavigationBarComponent
  ]
})
export class NavigationBarModule {
}
