<ng-content /> <!-- Fix missing hydratation anchor on ng-template with ngTemplateOutlet (see https://github.com/angular/angular/issues/50543#issuecomment-1572900259) -->
<div class="backdrop" *ngIf="isOpen" (click)="blur()"></div>
<div class="autocomplete">
  <form [formGroup]="form" [ngClass]="{'active': isActive}">
    <div class="icon" *ngIf="(iconTemplateRef$ | async) as iconTemplateRef;">
      <ng-template [ngTemplateOutlet]="iconTemplateRef">
      </ng-template>
    </div>
    <input type="search" name="query" formControlName="query" [placeholder]="options.placeholder" autocomplete="off"
      (keyup.enter)="onEnterPressed(form)" (focus)="activateForm()" (blur)="deactivateForm()">
  </form>
  <div class="hits" *ngIf="isOpen && displayedHits?.length">
    <div class="hit" (click)="onHitClicked(hit)" *ngFor="let hit of displayedHits">
      <ng-container *ngIf="(templateRef$ | async) as templateRef; else default">
        <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: hit}">
        </ng-template>
      </ng-container>
      <ng-template #default>
        <div [innerHTML]="hit.label"></div>
      </ng-template>
    </div>
    <ng-container *ngIf="(footerTemplateRef$ | async) as footerTemplateRef;">
      <ng-template [ngTemplateOutlet]="footerTemplateRef">
      </ng-template>
    </ng-container>
  </div>
</div>
