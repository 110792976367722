import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

/**
 * Provide the date across all the application.
 * Checks if the URL has a specific query param in order to override the date that is provided to the application.
 */
@Injectable({
  providedIn: 'root'
})
export class DateProvider {
  private overridenDate?: Date;

  constructor(router: Router) {
    router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        map(event => event as RoutesRecognized),
        tap(event => {
          const queryParams = event.state.root.queryParams;
          const dateParam = queryParams.overridedate;

          if (!dateParam) {
            return;
          }

          if (isNaN(Date.parse(dateParam))) {
            return;
          }

          this.overridenDate = new Date(dateParam);
        }),
      ).subscribe();
  }

  public currentDate(): Date {
    return this.overridenDate ?? new Date();
  }

  public now(): number {
    return this.currentDate().getTime();
  }
}
