import accountCircleOutline from '@iconify-icons/mdi/account-circle-outline';
import autorenew from '@iconify-icons/mdi/autorenew';
import bookOpenVariant from '@iconify-icons/mdi/book-open-variant';
import bookmarkOutline from '@iconify-icons/mdi/bookmark-outline';
import bullhorn from '@iconify-icons/mdi/bullhorn';
import calendarBlank from '@iconify-icons/mdi/calendar-blank';
import check from '@iconify-icons/mdi/check';
import checkCircle from '@iconify-icons/mdi/check-circle';
import checkCircleOutline from '@iconify-icons/mdi/check-circle-outline';
import chevronDown from '@iconify-icons/mdi/chevron-down';
import chevronLeft from '@iconify-icons/mdi/chevron-left';
import chevronRight from '@iconify-icons/mdi/chevron-right';
import chevronUp from '@iconify-icons/mdi/chevron-up';
import circleSmall from '@iconify-icons/mdi/circle-small';
import close from '@iconify-icons/mdi/close';
import closeCircle from '@iconify-icons/mdi/close-circle';
import download from '@iconify-icons/mdi/download';
import arrowExpand from '@iconify-icons/mdi/arrow-expand';
import filter from '@iconify-icons/mdi/filter';
import filterOutline from '@iconify-icons/mdi/filter-outline';
import flash from '@iconify-icons/mdi/flash';
import info from '@iconify-icons/mdi/info-circle-outline';
import laptopAccount from '@iconify-icons/mdi/laptop-account';
import linkedin from '@iconify-icons/mdi/linkedin';
import loading from '@iconify-icons/mdi/loading';
import magnify from '@iconify-icons/mdi/magnify';
import map from '@iconify-icons/mdi/map';
import menu from '@iconify-icons/mdi/menu';
import openInNew from '@iconify-icons/mdi/open-in-new';
import playCircle from '@iconify-icons/mdi/play-circle';
import questionMark from '@iconify-icons/mdi/question-mark';
import star from '@iconify-icons/mdi/star';
import startCheckOutline from '@iconify-icons/mdi/star-check-outline';
import currencyUsd from '@iconify-icons/mdi/currency-usd';
import starHalfOutlined from '@iconify-icons/mdi/star-half-full';
import starOutline from '@iconify-icons/mdi/star-outline';
import subdirectoryArrowRight from '@iconify-icons/mdi/subdirectory-arrow-right';
import tagOutline from '@iconify-icons/mdi/tag-outline';
import textLong from '@iconify-icons/mdi/text-long';
import trayArrowDown from '@iconify-icons/mdi/tray-arrow-down';
import twitter from '@iconify-icons/mdi/twitter';
import warningCircleOutline from '@iconify-icons/mdi/warning-circle-outline';
import web from '@iconify-icons/mdi/web';
import youtube from '@iconify-icons/mdi/youtube';
export const icons = {
  'account-circle-outline': accountCircleOutline,
  'book-open-variant': bookOpenVariant,
  'bookmark-outline': bookmarkOutline,
  bullhorn,
  'calendar-blank': calendarBlank,
  check,
  'check-circle': checkCircle,
  'check-circle-outline': checkCircleOutline,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'chevron-up': chevronUp,
  'circle-small': circleSmall,
  'close-circle': closeCircle,
  close,
  download,
  'arrow-expand': arrowExpand,
  filter,
  'filter-outline': filterOutline,
  flash,
  'info-circle-outline': info,
  'laptop-account': laptopAccount,
  linkedin,
  loading,
  magnify,
  map,
  menu,
  'currency-usd': currencyUsd,
  'open-in-new': openInNew,
  play: playCircle,
  'question-mark': questionMark,
  star,
  'star-half-outlined': starHalfOutlined,
  'star-outline': starOutline,
  'star-check-outline': startCheckOutline,
  'subdirectory-arrow-right': subdirectoryArrowRight,
  'tag-outline': tagOutline,
  'text-long': textLong,
  'tray-arrow-down': trayArrowDown,
  autorenew,
  twitter,
  'warning-circle-outline': warningCircleOutline,
  web,
  youtube
};
