import localePt from '@angular/common/locales/pt';

export const domain = {
  id: 'BR',
  locale: 'pt_BR',
  isoLocale: 'pt-BR',
  siteName: 'appvizer.com.br',
  ngLocale: localePt as any,
  algoliaIndexSuffix: 'BR',
  externalUrls: {
    support: 'https://help.appvizer.com/en',
    facebook: 'https://www.facebook.com/appvizer.br',
    linkedin: 'https://www.linkedin.com/company/appvizer-com-br',
    twitter: 'https://twitter.com/appvizer_br',
    youtube: 'https://www.youtube.com/c/Appvizer',
  },
  paths: {
    about: 'sobre',
    privacyPolicy: 'sobre/politica-de-privacidade',
    termsOfUse: 'sobre/termos-de-uso',
    transparency: 'sobre/transparencia',
    search: 'pesquisar',
    magazine: 'revista',
    compare: 'comparar',
    softwareReviews: 'avaliacoes',
    softwareAlternatives: 'alternativas',
    hostedLandingPage: 'cadastro',
    categories: 'categorias',
    author: 'autor',
    news: 'atualidade',
    sitemaps: 'sitemaps',
    magazineSitemap: 'sitemaps/revista',
    selfRegistration: 'parceiros/registrar-se',
    login: 'entrar',
    account: 'account/perfil',
    editorServices: 'editor/services',
    editorStatistics: 'editor/statistics',
    editorOffers: 'editor/offers',
    editorLeads: 'editor/leads',
    boProspects: 'bo/prospects',
    boVendors: 'bo/vendors',
    boSalesProposal: 'bo/sales-proposal/manage',
    boUsers: '-bo/users',
    boServices: 'bo/services',
    boItems: '-bo/items',
    boContent: 'bo/content',
    boSoftwareGroups: '-bo/software-groups',
    boTests: '-bo/tests'
  }
};
