import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule, TransferState } from '@angular/core';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { IconService } from '@miles170/iconify-angular';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { domain } from '../environments/domain';
import { AdminButtonsModule } from './admin-buttons/admin-buttons.module';
import { appInitializerFactory } from './app-initializer.factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './footer/footer.module';
import { GraphQLModule } from './graphql.module';
import { HeaderModule } from './header/header.module';
import { icons } from './icons';
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';
import { TranslateMessageFormatCompiler } from './shared/translate-compiler/translate-message-format-compiler';
import { DialogModule } from './widgets/dialog/dialog.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      },
      defaultLanguage: 'en-US'
    }),
    AppRoutingModule,
    GraphQLModule,
    HeaderModule,
    FooterModule,
    AdminButtonsModule,
    DialogModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: domain.locale.replace(/_/, '-') },
    DatePipe,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    provideClientHydration(withNoHttpTransferCache()),
    { provide: APP_ID, useValue: 'serverApp' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    iconService: IconService,
  ) {
    iconService.registerAll(icons);
  }
}
