<div class="my-account" *ngIf="(userRole$ | async) !== 'ANONYMOUS'">
  <input id="account-menu" type="checkbox">
  <label class="account-button" for="account-menu">
    <ic-icon icon="account-circle-outline" class="mobile-open"></ic-icon>
    <ic-icon icon="close" class="mobile-close"></ic-icon>
    <span class="account-label">{{menuActionLabel}}</span>
    <ic-icon class="desktop-close" icon="chevron-up"></ic-icon>
    <ic-icon class="desktop-open" icon="chevron-down"></ic-icon>
  </label>
  <div class="menu-overlay small">
    <form *ngIf="items">
      <div class="sub-item" *ngFor="let item of items; let index=index">
        <a [cimRedirect]="item.url" class="sub-item-tile default primary">
          {{item.name}}
        </a>
      </div>
    </form>
  </div>
</div>

<a [cimRedirect]="login.url" class="login default primary"
  *ngIf="login && (userRole$ | async) === 'ANONYMOUS'">
  <ic-icon icon="account-circle-outline" class="mobile-open"></ic-icon>
  <span class="account-label">{{menuActionLabel}}</span>
</a>
