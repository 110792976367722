import { UIStateMetadata } from './ui-state-metadata.model';
import { UIStatus } from './ui-status.enum';

export class UIState<T extends UIStateMetadata = UIStateMetadata> {
  status!: UIStatus;
  metadata?: T;

  constructor(status: UIStatus, metadata?: T) {
    this.status = status;
    this.metadata = metadata;
  }
}
