import localeEnUS from '@angular/common/locales/en';

export const domain = {
  id: 'COM',
  locale: 'en_US',
  isoLocale: 'en-US',
  siteName: 'appvizer.com',
  ngLocale: localeEnUS as any,
  algoliaIndexSuffix: 'COM',
  externalUrls: {
    support: 'https://help.appvizer.com/en',
    facebook: 'https://www.facebook.com/appvizer-1463061440585278',
    linkedin: 'https://www.linkedin.com/company/appvizer',
    twitter: 'https://twitter.com/appvizer',
    youtube: 'https://www.youtube.com/c/Appvizer',
  },
  paths: {
    about: 'about',
    privacyPolicy: 'about/privacy-policy',
    termsOfUse: 'about/terms-of-use',
    transparency: 'about/transparency',
    search: 'search',
    magazine: 'magazine',
    compare: 'compare',
    softwareReviews: 'reviews',
    softwareAlternatives: 'alternatives',
    hostedLandingPage: 'registration',
    categories: 'categories',
    author: 'author',
    news: 'news',
    sitemaps: 'sitemaps',
    magazineSitemap: 'sitemaps/magazine',
    selfRegistration: 'vendors/registration',
    login: 'login',
    account: 'account/profile',
    editorServices: 'editor/services',
    editorStatistics: 'editor/statistics',
    editorOffers: 'editor/offers',
    editorLeads: 'editor/leads',
    boProspects: 'bo/prospects',
    boVendors: 'bo/vendors',
    boSalesProposal: 'bo/sales-proposal/manage',
    boUsers: '-bo/users',
    boServices: 'bo/services',
    boItems: '-bo/items',
    boContent: 'bo/content',
    boSoftwareGroups: '-bo/software-groups',
    boTests: '-bo/tests'
  }
};
