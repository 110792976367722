import { Component, ElementRef, Input } from '@angular/core';
import { SoftwareTileComponent } from '../software-tile.component';
import { IntegralSoftwareTileState } from './integral-software-tile-state.model';
import { IntegralSoftwareTile } from './integral-software-tile.model';
import { DataAttributesService } from '../../../../widgets/data-attributes/data-attributes.service';
import { Cta } from '../../../models/cta.model';
import { Feature } from '../../../models/feature.model';

@Component({
  selector: 'cim-integral-software-tile',
  templateUrl: './integral-software-tile.component.html',
  styleUrls: ['./integral-software-tile.component.scss']
})
export class IntegralSoftwareTileComponent extends SoftwareTileComponent<IntegralSoftwareTile, IntegralSoftwareTileState> {
  public enableToggleFeatures = false;
  private _toggledFeatures!: boolean;
  public features: Array<Feature> = [];
  public displayResume!: boolean;
  public displayLinkLabel!: boolean;
  public displayFeaturesTitle!: boolean;

  public hasMainRedirect?: boolean;
  public mainUrl!: string;

  public get softwareTile(): IntegralSoftwareTile | undefined {
    return this._softwareTile;
  }

  @Input()
  public set softwareTile(value: IntegralSoftwareTile | undefined) {
    super.setSoftwareTile(value);
    if (value) {
      this.features = value.features || [];
      this.displayLinkLabel = !!value.linkLabel;
      this.displayResume = !!value.resume;
      this.displayFeaturesTitle = !!value.featureLabel;
      if (this._softwareTile) {
        this._softwareTile.cta = value.cta;
      }
    }

    this.computeMainRedirect();
  }

  @Input()
  public set cta(value: Cta | undefined) {
    if (!value) {
      return;
    }
    if (this._softwareTile) {
      this._softwareTile.cta = value;
    }
    if (this.softwareTile?.cta?.hasPaidCTA) {
      this.dataAttributes = this.dataAttributesService.generateSoftwareDataAttributes(this.softwareTile);
      this.dataAttributesDirective.dataAttributes = this.dataAttributes;

    }
    this.computeMainRedirect();
  }


  @Input()
  public set hasGlobalRedirect(value: boolean) {
    this.hasMainRedirect = value;
    this.computeMainRedirect();
  }

  /**
   * Once a value is given, the toggle feature will be in place (in mobile only).
   *
   * True = toggle opened by default
   * False = toggle closed by default
   */
  @Input()
  public set toggledFeatures(value: boolean) {
    this._toggledFeatures = value;
    this.enableToggleFeatures = true;
  }

  public get toggledFeatures(): boolean {
    return this._toggledFeatures;
  }

  toggleFeatures(event: any): void {
    event.stopPropagation();
    this._toggledFeatures = !this._toggledFeatures;
  }

  constructor(protected readonly dataAttributesService: DataAttributesService, protected readonly elementRef: ElementRef) {
    super(dataAttributesService, elementRef);
  }

  private computeMainRedirect(): void {
    if (this.softwareTile) {
      if (this.softwareTile.cta?.url && this.softwareTile.cta?.hasPaidCTA) {
        this.redirectSelectors.push(...['button.cta-big', '.cta-small']);
        if (this.hasMainRedirect) {
          this.redirectSelectors.push('.software-tile ');
          this.mainUrl = this.softwareTile.cta.url;
        }
      } else if (this.softwareTile.url) {
        if (this.hasMainRedirect) {
          this.mainUrl = this.softwareTile.url;
        }
      }
    }
  }

  protected onLoad(metadata: IntegralSoftwareTileState): void {
    super.onLoad();
    this.logoAlt = 'placeholder';
    this.features = Array.from(Array(metadata?.featuresCount || 0)).map(() => ({}));
    this.displayResume = metadata?.displayResume || false;
    this.displayLinkLabel = metadata?.displayLinkLabel || false;
    this.displayFeaturesTitle = metadata?.displayFeaturesTitle || false;
  }

  protected onSuccess(): void {
    super.onSuccess();
  }

  callback(event: any): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.softwareTile?.cta?.callback) {
      this.softwareTile.cta.callback();
    }
  }
}
