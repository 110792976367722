import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class RouteHelperService {

  getFromMostNestedRouteData<T>(route: ActivatedRoute, dataKey: string): T {
    if (!route.firstChild) {
      return route.snapshot.data[dataKey];
    } else {
      return this.getFromMostNestedRouteData(route.firstChild, dataKey);
    }
  }

  getFromMostNestedRouteParamMap(route: ActivatedRoute, paramMapKey: string): string | null {
    if (!route.firstChild) {
      return route.snapshot.paramMap.get(paramMapKey);
    } else {
      return this.getFromMostNestedRouteParamMap(route.firstChild, paramMapKey);
    }
  }

}
